import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';
// import { connectRouter, routerMiddleware } from 'connected-react-router'

import rootReducers from './Reducers';
import rootSaga from './Sagas';

// const history = createHistory();
const sagaMiddleware = createSagaMiddleware();
// const routeMiddleware = routerMiddleware(history);
const middlewares = [sagaMiddleware];

const createRootReducer = () => combineReducers({
    // router: connectRouter(history),
    ...rootReducers
})

const store = createStore(
    createRootReducer(),
    compose(applyMiddleware(...middlewares))
);
sagaMiddleware.run(rootSaga);
export { store };
