import BCActions from "./BCActions";
export interface S {
  nftAddress: string;
  tokenAddress: string;
  stakingAddress: string;

}
// const isLoggedIn = localStorage.getItem("isLoggedIn") === "ok" ? true : false;
// const selectBranch = localStorage.getItem("selectBranch") === "ok" ? true : false;

const initState: any = {
  nftAddress: "",
  tokenAddress: "",
  stakingAddress: "",

};
export default function (state: S = initState, action: any) {
  switch (action.type) {
    case BCActions.SET_ADDRESS_REQUEST:
      return {
        ...state,
        loading: true,
        nftAddress: action.props.nftAddress,
        tokenAddress: action.props.tokenAddress,
        stakingAddress: action.props.stakingAddress,
      };
    case BCActions.SET_ADDRESS_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case BCActions.SET_ADDRESS_ERROR:
      return {
        ...state,
        loading: false,
      };



    default:
      return state;
  }
}
