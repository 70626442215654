import React, { Component } from 'react'

export class DashboardPage extends Component {
    render() {
        return (
            <div>DashboardPage</div>
        )
    }
}

export default DashboardPage