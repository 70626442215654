import { ChainId, useAddress, useContract, useMetamask, useNetwork, useNFTDrop } from "@thirdweb-dev/react";
import { Col, Row, Image, Alert, notification } from "antd";
import { useNavigate } from "react-router-dom";
import MButton from "../components/MButton";
import MCard from "../components/MCard";
import { useForm } from 'react-hook-form';

import { PeaceRole } from "../data/PeaceRole";
import { hideSomeText } from "../methods/logics";

// import styles from "../styles/Home.module.css";
import { useState } from 'react';

import React, { Component } from 'react'
import { NFTDrop } from "@thirdweb-dev/sdk";
import { connect } from "react-redux";

interface Props {
    form: ReturnType<typeof useForm>;
    contract: NFTDrop
    navigate: ReturnType<typeof useNavigate>
    address: ReturnType<typeof useAddress>
    connectWithMetamask: ReturnType<typeof useMetamask>
    openNotification: any
    contextHolder: any
    switchNetwork: Function,
    currentNetwork: any;
    nftAddress: string;

}
interface State {
    loading: boolean,
}
export class MintPage extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            loading: false,
        }
    }
    setLoading(value: boolean) {
        this.setState({
            loading: value
        })
    }
    componentDidMount(): void {

        if (this.props.address) {

            if (process.env.NODE_ENV === "development") {
                this.props.switchNetwork(ChainId.BinanceSmartChainTestnet)
            } else {
                this.props.switchNetwork(ChainId.BinanceSmartChainMainnet)
            }
        }

    }
    claimNft = async () => {
        this.setLoading(true)
        console.log("start chaim", this.props.currentNetwork)

        try {
            if (process.env.NODE_ENV === "production" && ChainId.BinanceSmartChainMainnet !== this.props.currentNetwork.id) {
                this.setLoading(false)
                this.props.openNotification("info", "switch", "กรุณาสลับ network เป็น binance smart chain")
            } else if (process.env.NODE_ENV === "development" && ChainId.BinanceSmartChainTestnet !== this.props.currentNetwork.id) {
                console.log(ChainId.BinanceSmartChainTestnet, this.props.currentNetwork.id)
                this.setLoading(false)
                this.props.openNotification("info", "switch", "กรุณาสลับ network เป็น binance smart chain testnet")
            } else {
                const tx = await this.props.contract.claim(1)
                this.setLoading(false)
                this.props.openNotification("success", "claim success", "ดู NFT ของคุณได้ที่ collection")
            }

        } catch (error) {
            this.setLoading(false)
            console.log(error)
            this.props.openNotification("error", "claim failed", "การร้องของถูกปฏิเสธ")
        }


    }

    connectWallet = () => {
        this.props.connectWithMetamask()
    }
    render() {
        const { loading, } = this.state
        const { connectWithMetamask, contextHolder, address } = this.props
        return (
            <center >
                {contextHolder}
                <div style={{ marginTop: 84 }}>
                    <MCard >

                        <div style={{ justifyContent: 'center' }}>
                            <div>
                                <p className="title">Mint An NFT</p>
                                <p>{address && hideSomeText(address)}</p>
                                <Image preview={false} width={160} src={`https://gateway.pinata.cloud/ipfs/QmZPU2ufbP1vPvhCzwVN4D4JEv8vb1WeigdhFrwJV8xE1z/model-peace-robot.gif`} alt="drop" />
                                <p style={{ fontSize: 20, fontWeight: "bold" }}>0.029 BNB</p>
                                {!address ? (
                                    <MButton
                                        onClick={this.connectWallet}
                                        loading={loading}
                                    >
                                        Connect Wallet
                                    </MButton>
                                ) : (
                                    <MButton
                                        onClick={this.claimNft}
                                        loading={loading}
                                    >
                                        Claim An NFT
                                    </MButton>
                                )}
                            </div>
                            <div>
                                <p className='title'>
                                    คุณสมบัติ
                                </p>
                                <div style={{ marginTop: 10, textAlign: "left" }}>
                                    {PeaceRole.map((item, i) =>
                                        <p key={i}>{i + 1}. {item}</p>
                                    )}
                                </div>
                            </div>
                        </div>
                    </MCard>
                </div>
            </center>
        )
    }
}


// const MintPage = () => {
//     // Get the currently connected wallet's address
//     const navigate = useNavigate();//เปลี่ยน จาก useRroute
//     const address = useAddress();
//     const [loading, setLoading] = useState(false)

//     // Function to connect to the user's Metamask wallet
//     const connectWithMetamask = useMetamask();

//     // Get the NFT Collection contract
//     const nftDropContract = useNFTDrop(
//         "0x9Bf7eF8901b0A02C368291812b02B46665287a49"
//     );
//     const [api, contextHolder] = notification.useNotification();
//     const openNotification = () => {
//         api.open({
//             message: 'Notification Title',
//             description: 'description.',
//         });

//         setTimeout(() => {
//             api.open({
//                 message: 'New Title',
//                 description: 'New description.',
//             });
//         }, 1000);
//     };
//     const claimNft = async () => {
//         try {
//             setLoading(true)
//             const tx = await nftDropContract?.claim(1);
//             setLoading(false)
//             console.log(tx);
//             alert("NFT Claimed!");
//             navigate(`/Collection`);
//         } catch (error) {
//             // console.log(error);
//             setLoading(false)
//             openNotification()
//             // MNotification("claim failed", "")
//         }
//     }

//     return (
//         <center >
//             <div style={{ marginTop: 84 }}>
//                 <MCard >

//                     <div style={{ justifyContent: 'center' }}>
//                         <div>
//                             <p className="title">Mint An NFT</p>
//                             <p>{address && hideSomeText(address)}</p>
//                             <Image preview={false} width={160} src={`https://gateway.pinata.cloud/ipfs/QmZPU2ufbP1vPvhCzwVN4D4JEv8vb1WeigdhFrwJV8xE1z/model-peace-robot.gif`} alt="drop" />
//                             <p style={{ fontSize: 20, fontWeight: "bold" }}>0.029 BNB</p>
//                             {!address ? (
//                                 <MButton
//                                     onClick={connectWithMetamask}
//                                     loading={loading}
//                                 >
//                                     Connect Wallet
//                                 </MButton>
//                             ) : (
//                                 <MButton
//                                     onClick={claimNft}
//                                     loading={loading}
//                                 >
//                                     Claim An NFT
//                                 </MButton>
//                             )}
//                         </div>
//                         <div>
//                             <p className='title'>
//                                 คุณสมบัติ
//                             </p>
//                             <div style={{ marginTop: 10, textAlign: "left" }}>
//                                 {PeaceRole.map((item, i) =>
//                                     <p key={i}>{i + 1}. {item}</p>
//                                 )}
//                             </div>
//                         </div>
//                     </div>
//                 </MCard>
//             </div>
//         </center>
//     );
// };
function withFormHOC(Component: React.ComponentType<Props>) {
    function UseForm(props: any) {
        const form = useForm({
            mode: 'onTouched',
        });
        const { contract } = useContract(props.nftAddress, "nft-drop")
        const navigate = useNavigate()
        const address = useAddress();
        const connectWithMetamask = useMetamask();
        const [api, contextHolder] = notification.useNotification();
        const [{ data, error, loading }, switchNetwork] = useNetwork();
        const openNotification = (type: 'success' | 'info' | 'warning' | 'error', message: string, description?: string) => {
            api.open({
                message: message,
                description: description,
                type: type
            });
        };
        return <Component form={form} {...props} contract={contract}
            switchNetwork={switchNetwork}
            currentNetwork={data.chain}
            navigate={navigate}
            address={address}
            contextHolder={contextHolder}
            connectWithMetamask={connectWithMetamask}
            openNotification={openNotification} />;
    }
    return UseForm;
}
const mapState = ({ AuthReducers, BCReducers }: any) => {
    return {
        isLogin: AuthReducers.isLogin,
        loading: AuthReducers.loading,
        user: AuthReducers.user,
        nftAddress: BCReducers.nftAddress
    };
};
export default connect(mapState, {})(withFormHOC(withFormHOC(MintPage)))


