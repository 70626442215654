import { useEffect, useRef, useState } from 'react';
import firebase from '../firebase';
import { Layout, Row, Col, List, Input, Button, Avatar, message, Typography, Spin } from 'antd';
import { Configuration, OpenAIApi } from "openai";
import { UserOutlined, AndroidOutlined, SendOutlined } from '@ant-design/icons';
import axios from 'axios';
import { useAddress } from '@thirdweb-dev/react';
import { connect } from 'react-redux';
import MButton from '../components/MButton';

const firestore = firebase.firestore();
const { Header, Content } = Layout;
const openAi_APIKey = "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";
const configuration = new Configuration({
    apiKey: openAi_APIKey,
});
const { Title } = Typography;
const openai = new OpenAIApi(configuration);
interface Message {
    id: string;
    text: string;
    createdAt: Date;
    uid: string;
    type: "user" | "ai"
}
const PeaceBotPage = (props: any) => {
    const [messages, setMessages] = useState<Message[]>([]);
    const [text, setText] = useState('');
    const address = useAddress()
    const [loading, setloading] = useState(false);
    const [lastTextLoading, setLastTextLoading] = useState(false);
    const messagesEndRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        console.log("call user-agent")
        const interceptor = axios.interceptors.request.use((config) => {
            if (config.headers) {
                config.headers['User-Agent'] = 'Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/58.0.3029.110 Safari/537.3';
            }
            return config;
        });
        return () => {
            axios.interceptors.request.eject(interceptor);
        };
    }, []);


    const scrollToBottom = () => {
        if (messagesEndRef.current) {
            messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
        }
    }
    const listenForMessages = () => {

        setloading(true)

        if (address) {
            const chatRef = firestore.collection("chats").doc(address);
            const messagesRef = chatRef.collection("messages");

            let firstLoad = true;
            let latestMessage: any
            const unsubscribe = messagesRef
                .orderBy("createdAt", "desc")
                .limit(25)
                .onSnapshot((snapshot) => {
                    const messages: Message[] = [];

                    snapshot.forEach((doc) => {
                        const messageData = doc.data();
                        const message: Message = {
                            id: doc.id,
                            text: messageData.text,
                            createdAt: messageData.createdAt,
                            uid: messageData.uid,
                            type: messageData.type

                        };
                        messages.push(message);

                        if (firstLoad) {
                            latestMessage = doc;
                        }
                    });

                    if (firstLoad) {
                        window.scrollTo(0, document.body.scrollHeight);
                        firstLoad = false;
                    }
                    setMessages(messages.reverse())
                    setloading(false)
                    scrollToBottom()
                });
        } else {
            setloading(false)
        }

    };
    // useEffect(() => {
    //     console.log("call auth")
    //     auth.onAuthStateChanged((user) => {
    //         if (user) {
    //             setUid(user.uid);
    //         }
    //     });
    // }, []);


    useEffect(() => {
        console.log("call list")
        if (address && props.isLogin) {
            listenForMessages();
        }
    }, [address]);
    const handleSubmit = async () => {

        if (!text) {
            return;
        }
        setloading(true);
        setLastTextLoading(true)
        setText('')
        const chatRef = firestore.collection("chats").doc(address);
        const messagesRef = chatRef.collection("messages");

        try {
            await messagesRef.add({
                text,
                createdAt: firebase.firestore.FieldValue.serverTimestamp(),
                uid: address,
                type: "user"
            });

            const response = await submitMessageV1(text)

            if (response) {
                setLastTextLoading(false)
                await messagesRef.add({
                    // text: response.data.choices[0].text.trim(),
                    // text: response?.data.choices[0].message?.content,
                    text: response,
                    createdAt: firebase.firestore.FieldValue.serverTimestamp(),
                    uid: address,
                    type: "ai"
                });

            } else {
                setLastTextLoading(false)
                console.log(response)

            }
            scrollToBottom();
            setText('')
        } catch (error) {
            console.error(error);
            message.error("Failed to send message");
            setText('')
            setloading(false);
        } finally {
            setloading(false);
        }
    };
    const handleText = (text: string) => {
        const lines = text.split("\n");
        let newText = "";
        lines.forEach(line => {
            if (line.includes("STEP") || !isNaN(parseInt(line))) {
                newText += `\n${line}`;
            } else {
                newText += ` ${line}`;
            }
        });
        return newText;
    };
    const submitMessageV1 = async (message: string) => {
        let response = await openai.createChatCompletion({
            model: "gpt-3.5-turbo",
            messages: [{ role: "user", content: message }],
            // messages: [{ role: "user", content: `Input: ${message}\nOutput:` }],
            temperature: 0.9,
            max_tokens: 2000,
            top_p: 1.0,
            frequency_penalty: 0.0,
            presence_penalty: 0.0,
            // stop: '\n'
        });
        return response?.data.choices[0].message?.content;

    };
    const submitMessage = async (message: string) => {
        const maxMessageLength = 2000;
        try {
            const messageChunks = [];
            for (let i = 0; i < message.length; i += maxMessageLength) {
                messageChunks.push(message.substring(i, i + maxMessageLength));
            }
            const promises = messageChunks.map(async (chunk, i) => {
                console.log("chunk", chunk)
                let response = await openai.createChatCompletion({
                    model: "gpt-3.5-turbo",
                    messages: [{ role: "user", content: `Input: ${chunk}\nOutput:${chunk}\n` }],
                    temperature: 0.9,
                    max_tokens: 2000,
                    top_p: 1.0,
                    frequency_penalty: 0.0,
                    presence_penalty: 0.0,
                    // stop: '\n'
                });
                return {
                    message: response?.data.choices[0].message?.content,
                    index: i,
                };
            });
            const results = await Promise.all(promises);
            const sortedResults = results.sort((a, b) => a.index - b.index);
            const finalMessage = sortedResults.reduce(
                (acc, curr) => acc + curr.message,
                ""
            );
            return finalMessage;
        } catch (error) {
            console.log(error);
            return "";
        }
    };
    if (!address && !props.isLogin) {
        return (
            <div style={{
                height: 'calc(100vh - 64px)',
                backgroundColor: "white"
            }}>
                <Row style={{ height: '80vh', justifyContent: 'center', alignContent: 'center' }}>

                    <p style={{ color: "black", fontSize: 24 }}>กรุณาล็อคอิน metamask</p>

                </Row>
            </div>
        )
    }
    return (

        <div style={{
            height: 'calc(100vh - 64px)',
            backgroundColor: "white"
        }}>
            <Row style={{ height: '80vh' }}>

                <List
                    style={{ height: '100%', overflowY: 'auto', width: '100%' }}
                    dataSource={messages}
                    loading={loading}
                    renderItem={(item) => (
                        item.type === 'user' ?

                            <List.Item
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row-reverse',
                                    alignItems: 'flex-end',
                                }}
                            >
                                <div
                                    style={{
                                        marginRight: '10px',
                                        borderRadius: '15px',
                                        backgroundColor: '#e5e5e5',
                                        padding: '10px 15px',
                                        maxWidth: '60%',
                                    }}
                                >
                                    <p style={{ margin: 0, fontSize: '16px', color: 'black' }}>{item.text}</p>
                                    {/* {item.text.split('STEP').map((text) => (
                                            text.split('Step').map((text2, index) => (
                                                <p key={index} style={{ margin: 0, fontSize: '16px' }}>
                                                    {text2}
                                                </p>
                                            ))

                                        ))} */}
                                </div>

                            </List.Item>

                            :
                            <List.Item
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'flex-start',
                                }}
                            >
                                <div style={{
                                    flexDirection: 'row',
                                    display: 'flex',
                                    width: "100%"
                                }}>
                                    <div style={{ padding: 5 }}>
                                        <AndroidOutlined style={{ fontSize: 30 }} />

                                    </div>
                                    <div
                                        style={{
                                            marginLeft: '10px',
                                            borderRadius: '15px',
                                            padding: '10px 15px',
                                            maxWidth: '60%',
                                            border: "1px solid #e5e5e5"

                                        }}
                                    >
                                        <p style={{ margin: 0, fontSize: '16px', color: "black" }}>{item.text}</p>
                                        {/* {item.text.split('STEP').map((text) => (
                                                text.split('Step').map((text2, index) => (
                                                    <p key={index} style={{ margin: 0, fontSize: '16px' }}>
                                                        {text2}
                                                    </p>
                                                ))

                                            ))} */}
                                    </div>
                                </div>

                            </List.Item>
                    )}

                >
                    {lastTextLoading && <Spin ><p></p></Spin>}
                </List>
                <div ref={messagesEndRef} />
            </Row>
            <div
                style={{
                    position: 'absolute',
                    bottom: 0,
                    width: '100%',
                    backgroundColor: '#f2f2f2',
                    display: 'flex',
                    alignItems: 'center',
                    padding: '10px 15px',
                }}
            >
                <Input
                    value={text}
                    onChange={(e) => setText(e.target.value)}
                    onPressEnter={handleSubmit}
                    placeholder="Type your message..."
                    style={{ flex: 1, marginRight: '10px' }}
                />
                <Button
                    type="primary"
                    shape="circle"
                    icon={<SendOutlined />}
                    onClick={handleSubmit}
                />
            </div>
        </div>
    );
};
const mapState = ({ AuthReducers, BCReducers }: any) => {
    return {
        isLogin: AuthReducers.isLogin,
        loading: AuthReducers.loading,
        user: AuthReducers.user,
    };
};
export default connect(mapState, {})(PeaceBotPage)