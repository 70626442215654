import {
    UnorderedListOutlined,
    UploadOutlined,
    UserOutlined,
    VideoCameraOutlined,
    MenuUnfoldOutlined,
    MenuFoldOutlined,
    MenuOutlined
} from '@ant-design/icons'
import { Layout, Image, Menu, Drawer, Row, Col } from "antd"
import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { themeColor } from '../assets/theme'
import AppMenu from './AppMenu'
const { Header, Content, Footer, Sider } = Layout;

interface State {
}
export class AppLayout extends Component<any, State> {
        constructor(props: any) {
            super(props)
            this.state = {
            }
        }
    componentDidMount(): void {
        console.log("layout")
    }
    render() {
        return (
            <Layout >
                <Header style={{ position: 'fixed', top: 0, zIndex: 2, width: '100vw', backgroundColor: themeColor.navbar, }}>
                    <AppMenu ></AppMenu>
                </Header>
                <Content style={{ backgroundColor: themeColor.bg, minHeight: "100vh", zIndex: 1 }}>
                    <div style={{ marginTop: 64 }}>
                        {this.props.content}
                    </div>
                </Content>
                <Footer style={{ textAlign: 'center', backgroundColor: themeColor.navbar, padding: 0 }}>

                    <div style={{ padding: 10, }}>
                        <Image preview={false} width={32} src="https://bafkreibvo2vn37epeit2pbq4wznjgj7hzu6gjttjuzgnhqrcigss4ueogq.ipfs.nftstorage.link" alt="discord" />
                        <div>
                            <a href="https://discord.gg/KNMRxbSa4Mce" target={"_blank"} style={{ color: "white" }}>
                                Discord
                            </a>
                        </div>

                    </div>
                    <div style={{ backgroundColor: themeColor.bgMenu }}>
                        <p style={{ color: "white", margin: 0, padding: 3 }}>© 2023</p>
                    </div>

                </Footer>
            </Layout>
        )

    }
}

export default AppLayout