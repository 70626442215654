import { useContract, useNFT, useNFTs, useOwnedNFTs } from '@thirdweb-dev/react';
import { NFT } from '@thirdweb-dev/sdk';
import { Button, Col, Row, Spin } from 'antd';
import { Link } from 'react-router-dom';
import PeaceNFT from '../../components/PeaceNFT';
import { AnkrProvider, Nft } from '@ankr.com/ankr.js';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
const provider = new AnkrProvider();
interface Props {
    address: string
    nftAddress: string
}

function MyNFTs(props: Props) {
    const { contract, isLoading: contractLoading } = useContract(props.nftAddress)

    const [ownedNfts, setOwnedNfts] = useState<Nft[]>([]);
    const { data, isLoading, error } = useOwnedNFTs(contract, props.address);
    useEffect(() => {
        getNfts()
    }, [ownedNfts])
    async function getNfts() {
        const { assets } = await provider.getNFTsByOwner({
            blockchain: "bsc",
            walletAddress: props.address,
        });
        setOwnedNfts(assets)

    }

    return (
        <>
            {contractLoading ?
                <Row style={{ justifyContent: 'center' }} >
                    <Spin tip="Loading" size="large" >

                    </Spin>
                </Row>
                : ownedNfts && ownedNfts.length === 0 ?
                    <Link to="/nft/mint">
                        <Button shape="round" type="primary" >Mint NFT</Button>
                    </Link>
                    : ownedNfts?.map((itemNft) => (
                        <Col
                            key={itemNft.tokenId}
                            style={{ padding: 20 }}>
                            <PeaceNFT
                                nft={itemNft}
                            >
                            </PeaceNFT>
                        </Col>

                    ))}
        </>
    );
}

export default MyNFTs