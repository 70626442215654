import { GoogleOutlined, LockOutlined, MailOutlined } from '@ant-design/icons';
import { Alert, Button, Form, Input, Image, Row, Spin } from 'antd';
import React, { Component } from 'react'
import { useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import firebase from '../../firebase';
import AuthActions from '../../reduxs/auth/AuthActions';
import { RoleName } from '../../constants/RoleName.constants';
import openNotification from '../../components/MNotic';
import { themeColor } from '../../assets/theme';
import main_logo from "../../assets/image/main_logo.png"
import { TableName } from '../../constants/TableName.constants';
import { IUser } from '../../interface/data/IUser.interface';
import MLoading from '../../components/MLoading';
import BCActions from '../../reduxs/blockchain/BCActions';


const auth = firebase.auth();
const firestore = firebase.firestore();
interface Props {
    isLogin: boolean
    setAuth: Function;
    setAddress: Function;
    navigate: ReturnType<typeof useNavigate>;
    role: string
}
interface State {
    email: string,
    password: string
    pageLoading: boolean,
    loading: boolean,
    textAlert: String
}
export class AdminLogin extends Component<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = {
            email: "",
            password: "",
            pageLoading: true,
            loading: false,
            textAlert: ""
        }
        if (props.isLogin) {
            this.props.navigate("/home")
        }
    }
    componentDidMount(): void {
        auth.onAuthStateChanged(async (user) => {
            if (user) {
                // User is signed in, set the user to Redux state
                const userDoc = await firestore.collection(TableName.Users).where("uid", '==', user.uid).get()
                if (userDoc.docs.length !== 0) {
                    const userData = userDoc.docs[0].data()
                    if (userData.role === RoleName.Admin) {
                        const user: IUser = {
                            uid: userData.uid,
                            contractAddress: userData.contractAddress,
                            role: userData.role,
                            name: userData.name,

                        }
                        this.props.setAuth(true, user)
                        this.setState({
                            pageLoading: false
                        })
                    } else {
                        this.props.navigate("/docs")
                    }

                }

            } else {
                console.log("can not login")
                this.setState({
                    pageLoading: false
                })
            }
        })
    }
    handleEmailLogin = () => {
        const { email, password } = this.state;
        this.setState({
            loading: true
        })
        auth.signInWithEmailAndPassword(email, password)
            .then(async (userCredential) => {
                // Signed in
                if (userCredential) {
                    const userDoc = await firestore.collection(TableName.Users).where("uid", '==', userCredential.user?.uid).get()

                    if (userDoc.docs.length !== 0) {
                        const userData = userDoc.docs[0].data()
                        if (userData.role === RoleName.Admin) {
                            const user: IUser = {
                                uid: userData.uid,
                                contractAddress: userData.contractAddress,
                                role: userData.role,
                                name: userData.name,

                            }
                            this.props.setAuth(true, user)
                            this.setState({
                                loading: false
                            })
                            this.props.navigate("/admin/dashboard")
                        } else {
                            this.setState({
                                textAlert: "คุณไม่ใช่ Admin",
                                loading: false
                            })
                        }

                    }

                } else {
                    this.setState({
                        textAlert: "คุณไม่ใช่ Admin",
                        loading: false
                    })
                }


            })
            .catch((error) => {
                const errorCode = error.code;
                const errorMessage = error.message;
                console.log(errorCode, errorMessage);
                this.setState({
                    textAlert: "คุณไม่ใช่ Admin",
                    loading: false
                })
            });
    };

    render() {
        console.log(this.props.isLogin)
        const { email, password } = this.state;
        return (
            <center style={{ backgroundColor: themeColor.bg, height: '100vh', }}>
                {this.state.pageLoading ?
                    <div style={{ padding: 20, width: '80vw', marginTop: 128 }}>
                        <Row style={{ justifyContent: 'center' }} >
                            <Spin tip="Loading" size="large" >

                            </Spin>
                        </Row>
                    </div>
                    : <div style={{ padding: 20, width: '80vw', marginTop: 128 }}>

                        <Form name="login-form" onFinish={this.handleEmailLogin}>
                            <div style={{ padding: 10 }}>
                                <Image preview={false} style={{ maxWidth: 150 }} src={main_logo} alt="mp1" />
                            </div>
                            <p style={{ color: "#ff0000" }}>{this.state.textAlert}</p>
                            <Form.Item name="email" style={{ maxWidth: 360 }} rules={[{ required: true, message: 'Please input your email!' }]}>
                                <Input prefix={<MailOutlined />} placeholder="Email" value={email} onChange={(e) => this.setState({ email: e.target.value })} />
                            </Form.Item>
                            <Form.Item name="password" style={{ maxWidth: 360 }} rules={[{ required: true, message: 'Please input your password!' }]}>
                                <Input.Password prefix={<LockOutlined />} placeholder="Password" value={password} onChange={(e) => this.setState({ password: e.target.value })} />
                            </Form.Item>
                            <div style={{ flexDirection: "column", display: 'inline-grid' }}>
                                <Button style={{ margin: 5 }} loading={this.state.loading} type="primary" htmlType="submit">Login</Button>
                            </div>
                        </Form>
                    </div>
                }
            </center>
        )
    }
}

const { setAuth } = AuthActions
const { setAddress } = BCActions
const mapState = ({ AuthReducers }: any) => {
    return {
        isLogin: AuthReducers.isLogin,
        user: AuthReducers.user,
    };
};
function withFormHOC(Component: React.ComponentType<Props>) {
    function UseForm(props: any) {
        const form = useForm({
            mode: 'onTouched',
        });
        const navigate = useNavigate()

        console.log(
            props
        )
        return <Component form={form} {...props}
            navigate={navigate}

        />
    }
    return UseForm;
}

export default connect(mapState, { setAuth })(withFormHOC(AdminLogin))
