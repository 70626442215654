import React, { Component } from 'react'
import { Breadcrumb, Layout, Menu, Button, Image, Row, Col, Typography } from 'antd';
import welcomeImg from "../assets/image/welcomepage.jpg"
import peace_logo_1 from "../assets/image/peace_logo_1.jpg"
import { themeColor } from '../assets/theme';
import ShadowCard from '../components/ShadowCard';
const { Title } = Typography;
export class HomePage extends Component {
    render() {
        return (
            <div style={{ marginBottom: 64, padding: 10 }}>
                <Col>
                    <center>
                        <ShadowCard
                            image={"https://bafkreihnumiaieoepdkfo7spul24rbvc276uepasuyoo6ivnsjt2ibtyly.ipfs.nftstorage.link"}
                            title={"MetaPeace"}
                            description="เป็นแพลตฟอร์มที่รวมโลกจริงและโลกเสมือนจริงเข้าด้วยกัน เพื่อให้เกิดพื้นที่แบบครบวงจรสำหรับความคิดสร้างสรรค์
                             ความบันเทิง การเข้าสังคม การเรียนรู้ และการทำงานร่วมกัน ด้วยการใช้ประโยชน์จากเทคโนโลยี Web 3.0,AI, DApp 
                             (Decentralized Application) ทำให้ผู้ใช้สามารถมีอิสระในการใช้งานและไม่ต้องพึ่งพาศูนย์กลางใดๆ DAO (Decentralized Autonomous Organization)"

                        />

                    </center>
                </Col>

            </div>
        )
    }
}

export default HomePage