export const TableName = {
    Products: "Products",
    Orders: "Orders",
    Users: "Users",
    Chats: "Chats",
    Messages: "Messages",
    PeaceScriptOrders: "PeaceScriptOrders",
    PeaceScripts: "PeaceScripts",
    Configs: "Configs",



}