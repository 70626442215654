import { ThirdwebNftMedia } from "@thirdweb-dev/react";
import { Button, Col, Row, Spin, Image, Modal } from 'antd';
import { AnkrProvider, Nft } from '@ankr.com/ankr.js';
import { useState } from "react";
import { hideSomeText } from './../methods/logics';
interface Props {
    children?: React.ReactNode;
    style?: React.CSSProperties;
    nft: Nft;
}

const PeaceNFT: React.FC<Props> = ({ style, children, nft }) => {

    const [isModalOpen, setIsModalOpen] = useState(false);


    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleOk = () => {
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };

    return <div>
        <div onClick={showModal}>
            <Image preview={false} style={{ width: 150, height: "auto", borderRadius: 25 }} src={nft.imageUrl} alt={nft.name} ></Image>

            <div style={{ marginTop: 5 }}>
                <p>{nft.name}</p>
            </div>
        </div>

        <Modal title={nft.name} maskStyle={{ backgroundColor: "gray" }}
            open={isModalOpen} onOk={handleOk} onCancel={handleCancel} footer={[<Button onClick={handleOk} key="submit" type="primary">ตกลง</Button>]}>
            <Row style={{ justifyContent: 'center' }}>
                <Col>
                    <Image preview={false} style={{ width: 200, height: "auto", borderRadius: 25 }} src={nft.imageUrl} alt={nft.name} ></Image>

                </Col>
                <Col>
                    <div>
                        <p className="subTitle" style={{ color: "black" }}>ข้อมูล</p>
                    </div>

                    <Row gutter={[16, 0]} style={{ justifyContent: 'center' }}>
                        {nft.traits && nft.traits.map((item) =>
                            <Col span={8} key={item.trait_type} style={{ border: '1px solid #e5e7eb', textAlign: 'center', padding: 5, margin: "0 0 5px 5px " }}>
                                <p style={{ color: "gray" }}>{item.trait_type}</p>
                                <p style={{ color: "black" }}>{item.value}</p>
                            </Col>
                        )}

                    </Row>

                </Col>

            </Row>

        </Modal>
    </div>
}

export default PeaceNFT;