import React from "react";
import { Form, Input, Button, message, List, Avatar, Image, Upload, Modal } from "antd";
import firebase from "../../firebase";
import { useLocation, useNavigate } from "react-router-dom";
import { useForm } from "antd/es/form/Form";
import { PlusOutlined, UploadOutlined } from "@ant-design/icons";
import { connect } from "react-redux";
import { IPeaceScript } from "../../interface/IPeaceScript.interface";
import { IPeaceScriptOrder } from "../../interface/IPeaceScriptOrder.interface";
import { TableName } from "../../constants/TableName.constants";
const auth = firebase.auth();
const firestore = firebase.firestore();
const storage = firebase.storage();
interface Props {
    form: any
    navigate: ReturnType<typeof useNavigate>
    location: ReturnType<typeof useLocation>
    user: any
}



interface State {
    loading: boolean;
    peaceScripts: IPeaceScript[],
    buyModalVisible: boolean,
    uploading: boolean;
    buyer: IPeaceScriptOrder
}

class PeaceScriptShopPage extends React.Component<Props, State> {
    unsubscribe = () => { };
    constructor(props: Props) {
        super(props)
        this.state = {
            loading: false,
            peaceScripts: [],
            buyModalVisible: false,
            uploading: false,
            buyer: {
                contractAddress: '',
                phoneNumber: '',
                peaceScriptId: '',
                createdAt: new Date(),
                updatedAt: new Date(),
                status: "processing",
            }
        }

    }

    componentDidMount(): void {
        this.unsubscribe = firestore.collection(TableName.PeaceScripts).onSnapshot(snapshot => {
            const productsData = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })) as IPeaceScript[];
            this.setState({ peaceScripts: productsData });
        });
    }

    componentWillUnmount() {
        this.unsubscribe();
    }
    handleCancel = () => {
        this.setState({
            buyModalVisible: false
        })
    }
    handleSubmit = async () => {
        const purchasesRef = firestore.collection(TableName.PeaceScriptOrders);
        const { buyer: { peaceScriptId, phoneNumber } } = this.state
        const purchase: IPeaceScriptOrder = {
            contractAddress: this.props.user.id,
            peaceScriptId,
            phoneNumber,
            createdAt: new Date(),
            updatedAt: new Date(),
            status: "processing",
        };
        await purchasesRef.add(purchase).then(() => {
            message.success("buy product success")
        }).catch((error) => {
            console.log(error)
            message.error("failed to buy product")
        })
    }
    render() {
        const { loading, peaceScripts, uploading, } = this.state;

        return (
            <div style={{ padding: 20 }}>
                <div className="bg-gray-200 py-8">
                    <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
                        <p className="text-2xl font-bold mb-4">สินค้าทั้งหมด</p>
                        <List
                            itemLayout="horizontal"
                            dataSource={peaceScripts}
                            style={{ backgroundColor: '#c0c0c0', height: 400 }}
                            renderItem={(item: IPeaceScript) => {
                                return (
                                    <List.Item>
                                        <List.Item.Meta
                                            avatar={<Image width={200} src={item.imageUrl} />}
                                            title={<a href={`/product/${item.id}`}>{item.name}</a>}
                                            description={item.description}
                                        />
                                        <div className="flex flex-col justify-between">
                                            <Button type="primary" size="large" block
                                                onClick={() => this.setState({
                                                    buyModalVisible: true,
                                                    buyer: { ...this.state.buyer, peaceScriptId: item.id ? item.id : "" }
                                                })}>
                                                สั่งซื้อสินค้า
                                            </Button>
                                        </div>
                                    </List.Item>
                                );
                            }}
                        />
                        <Modal
                            title="สั่งซื้อสินค้า"
                            open={this.state.buyModalVisible}
                            onCancel={this.handleCancel}
                            footer={[
                                <Button key="cancel" onClick={this.handleCancel}>
                                    ยกเลิก
                                </Button>,
                                <Button key="submit" type="primary" onClick={this.handleSubmit}>
                                    สั่งซื้อ
                                </Button>,
                            ]}
                        >
                            <Form layout="vertical">
                                <Form.Item label="เบอร์โทรศัพท์" required>
                                    <Input value={this.state.buyer.phoneNumber} name="phoneNumber" onChange={(e) => this.setState({ buyer: { ...this.state.buyer, phoneNumber: e.target.value } })} />
                                </Form.Item>

                            </Form>
                        </Modal>
                    </div>
                </div>
            </div>

        );
    }
}
const mapState = ({ AuthReducers }: any) => {
    return {
        isLogin: AuthReducers.isLogin,
        user: AuthReducers.user
    };
};
function withFormHOC(Component: React.ComponentType<Props>) {
    function UseForm(props: any) {
        const form = useForm();
        const navigate = useNavigate()
        const location = useLocation();
        return <Component form={form} {...props}
            navigate={navigate}
            location={location}

        />
    }
    return UseForm;
}




export default connect(mapState, {})(withFormHOC(PeaceScriptShopPage))
