import React, { Component } from 'react'
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { ChainId, ThirdwebProvider } from "@thirdweb-dev/react";
import RootRoute from "./routes/Index"
import "./styles/globals.css";
import Cookies from 'js-cookie';
import { BinanceTestnet, Binance } from "@thirdweb-dev/chains";
import { store } from './reduxs/Store'
import { Provider } from 'react-redux'

const checkForPhishing = require('eth-phishing-detect')

// import detectEthereumProvider from '@metamask/detect-provider'

Cookies.set('cookieName', 'cookieValue', { sameSite: 'strict', secure: true });
export class App extends Component {

  componentDidMount(): void {
    const value = checkForPhishing('metapeaceofficial.com')
    console.log("webPhishing", value) // true
    // if (typeof window.ethereum !== 'undefined') {
    //   if (window.ethereum.isMetaMask) {
    //     console.log('MetaMask ตรวจพบแล้ว');
    //   } else {
    //     console.log('ตรวจพบแล้ว แต่ไม่ใช่ MetaMask');
    //     // this.downloadMetamask()
    //   }
    // } else {
    //   console.log('ไม่มีMetaMask');
    //   // this.downloadMetamask()
    // }

  }
  openMetaMask = () => {
    const isAndroid = /Android/i.test(navigator.userAgent);
    const isIOS = /iPhone|iPad|iPod/i.test(navigator.userAgent);

    if (isAndroid) {
      // นำผู้ใช้ไปยัง Google Play Store สำหรับ MetaMask
      // window.location.href = 'https://play.google.com/store/apps/details?id=io.metamask';
    } else if (isIOS) {
      // นำผู้ใช้ไปยัง App Store สำหรับ MetaMask
      // window.location.href = 'https://apps.apple.com/app/metamask-blockchain-wallet/id1438144202';
    } else {
      window.location.href = 'https://chrome.google.com/webstore/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn';
    }
  }



  render() {
    // const isAndroid = /Android/i.test(navigator.userAgent);
    // const isIOS = /iPhone|iPad|iPod/i.test(navigator.userAgent);
    // if (isAndroid) {
    //   // นำผู้ใช้ไปยัง Google Play Store สำหรับ MetaMask
    //   // window.location.href = 'https://play.google.com/store/apps/details?id=io.metamask';
    // } else if (isIOS) {
    //   // นำผู้ใช้ไปยัง App Store สำหรับ MetaMask
    //   // window.location.href = 'https://apps.apple.com/app/metamask-blockchain-wallet/id1438144202';
    // } else {
    //   window.location.href = 'https://chrome.google.com/webstore/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn';
    // }
    return (
      <Provider store={store}>
        <ThirdwebProvider
          activeChain={process.env.NODE_ENV === "development" ? BinanceTestnet : Binance}
          walletConnectors={["metamask",]}
        //test
        // authConfig={{
        //   authUrl: "/api/auth",
        //   domain: process.env.NODE_ENV === "development" ? "http://localhost:3000/" : "metapeaceofficial.com",
        // }}
        >
          <RootRoute></RootRoute>
        </ThirdwebProvider>
      </Provider>
    )
  }
}

export default App