
import firebase from '../../firebase'
import { IUser } from '../../interface/data/IUser.interface';
const auth = firebase.auth();
const AuthActions = {


    LOGIN_REQUEST: 'LOGIN_REQUEST',
    LOGIN_SUCCESS: 'LOGIN_SUCCESS',
    LOGIN_ERROR: 'LOGIN_ERROR',

    LOGOUT_REQUEST: 'LOGOUT_REQUEST',
    LOGOUT_SUCCESS: 'LOGOUT_SUCCESS',
    LOGOUT_ERROR: 'LOGOUT_ERROR',

    Unauthorized: 'Unauthorized',
    Forbidden: 'Forbidden',

    SET_AUTH: 'SET_AUTH',

    Logout: (onSuccess: any) => ({
        type: AuthActions.LOGOUT_REQUEST,
        onSuccess
    }),

    setAuth: (isLogin: boolean, user?: IUser) => ({
        type: AuthActions.SET_AUTH,
        payload: isLogin,
        user: user,
    }),
}

export default AuthActions;