import {
    ThirdwebNftMedia,
    useAddress,
    useMetamask,
    useNFTDrop,
    useToken,
    useTokenBalance,
    useOwnedNFTs,
    useContract,
} from "@thirdweb-dev/react";
import { BigNumber, ethers } from "ethers";
import { useEffect, useState } from "react";
import { Button, Col, Image, Row } from "antd"
import { Link } from "react-router-dom";
import MButton from "../components/MButton";



const nftDropContractAddress = "0x9Bf7eF8901b0A02C368291812b02B46665287a49";
const tokenContractAddress = "0xe8C1297F41abCBEF77C381cB70F91cF05A32507C";
const stakingContractAddress = "0xe6AC68F5ebAd056801f1b3efed3dCD0133562CCF";

const StackPage = () => {
    // Wallet Connection Hooks
    const address = useAddress();
    const connectWithMetamask = useMetamask();

    // Contract Hooks
    const nftDropContract = useNFTDrop(nftDropContractAddress);
    const tokenContract = useToken(tokenContractAddress);

    const { contract, isLoading } = useContract(stakingContractAddress);

    // Load Unstaked NFTs
    const { data: ownedNfts } = useOwnedNFTs(nftDropContract, address);

    // Load Balance of Token
    const { data: tokenBalance } = useTokenBalance(tokenContract, address);

    ///////////////////////////////////////////////////////////////////////////
    // Custom contract functions
    ///////////////////////////////////////////////////////////////////////////
    const [stakedNfts, setStakedNfts] = useState<any[]>([]);
    const [claimableRewards, setClaimableRewards] = useState<BigNumber>();

    useEffect(() => {
        if (!contract) return;

        async function loadStakedNfts() {
            const stakedTokens = await contract?.call("getStakedTokens", address);

            // For each staked token, fetch it from the sdk
            const stakedNfts = await Promise.all(
                stakedTokens?.map(
                    async (stakedToken: { stakes: string; tokenId: BigNumber }) => {
                        const nft = await nftDropContract?.get(stakedToken.tokenId);
                        return nft;
                    }
                )
            );
            setStakedNfts(stakedNfts);
            console.log("stakedNfts", stakedNfts);
            console.log("ownedNfts", ownedNfts);
        }

        if (address) {
            loadStakedNfts();
        }
    }, [address, contract, nftDropContract]);

    useEffect(() => {
        if (!contract || !address) return;

        async function loadClaimableRewards() {
            const cr = await contract?.call("availableRewards", address);
            console.log("Loaded claimable rewards", cr);
            setClaimableRewards(cr);
        }

        loadClaimableRewards();
    }, [address, contract]);

    ///////////////////////////////////////////////////////////////////////////
    // Write Functions
    ///////////////////////////////////////////////////////////////////////////
    async function stakeNft(id: BigNumber) {
        if (!address) return;

        const isApproved = await nftDropContract?.isApproved(
            address,
            stakingContractAddress
        );
        // If not approved, request approval
        if (!isApproved) {
            await nftDropContract?.setApprovalForAll(stakingContractAddress, true);
        }
        const stake = await contract?.call("stake", id);
    }

    async function withdraw(id: BigNumber) {
        const withdraw = await contract?.call("withdraw", id);
    }

    async function claimRewards() {
        const claim = await contract?.call("claimRewards");
    }

    if (isLoading) {
        return <div>Loading</div>;
    } else {
        return <center>
            <Image preview={false} src={`https://gateway.pinata.cloud/ipfs/QmZPU2ufbP1vPvhCzwVN4D4JEv8vb1WeigdhFrwJV8xE1z/icon-nfts-staking04.gif`} alt="drop" />
            <p style={{ fontSize: 64, fontWeight: "bold" }}>Stake NFTs</p>
            <hr style={{ width: "80%", borderColor: "grey", opacity: "0.25" }} />
            <p className="title">Comming soon...</p>
        </center>
    }

    return (
        <center>
            <Image preview={false} src={`https://gateway.pinata.cloud/ipfs/QmZPU2ufbP1vPvhCzwVN4D4JEv8vb1WeigdhFrwJV8xE1z/icon-nfts-staking04.gif`} alt="drop" />
            <p style={{ fontSize: 64, fontWeight: "bold" }}>Stake NFTs</p>
            <hr style={{ width: "80%", borderColor: "grey", opacity: "0.25" }} />
            {!address ? (
                <p className="title">Comming soon...</p>

                // <MButton onClick={connectWithMetamask}>
                //     Connect Wallet
                // </MButton>
            ) : (
                <>
                    <p style={{ fontSize: 40, fontWeight: "bold" }}>Your Tokens</p>

                    <Row style={{ justifyContent: 'center' }}>
                        <Col style={{
                            border: '1px solid grey',
                            borderRadius: 10,
                            flexDirection: 'column',
                            padding: 10,
                            width: 400,
                            margin: 10
                        }}>
                            <div>
                                <p className="subTitle">Claimable Rewards</p>
                            </div>
                            <div>
                                <p className="subTitle">
                                    {/* <b>
                                        {!claimableRewards
                                            ? "Loading..."
                                            : ethers.utils.formatUnits(claimableRewards, 18)}
                                    </b>{" "} */}
                                    {tokenBalance?.symbol}
                                </p>
                            </div>
                        </Col>
                        <Col style={{
                            border: '1px solid grey',
                            borderRadius: 10,
                            flexDirection: 'column',
                            padding: 10,
                            width: 400,
                            margin: 10
                        }}>
                            <div>
                                <p className="subTitle">Current Balance</p>
                            </div>
                            <div>
                                <p className="subTitle">
                                    <b>{tokenBalance?.displayValue}</b> {tokenBalance?.symbol}
                                </p>
                            </div>
                        </Col>
                    </Row>

                    {/* <MButton

                        onClick={() => claimRewards()}
                    >
                        <p style={{ fontSize: 24, marginBottom: 0 }}>
                            Claim Rewards
                        </p>
                    </MButton> */}


                    <hr style={{ width: "80%", borderColor: "grey", opacity: "0.25" }} />
                    <p style={{ fontSize: 40, fontWeight: "bold" }}>Your Staked NFTs</p>
                    <hr style={{ width: "80%", borderColor: "grey", opacity: "0.25" }} />
                    <div >
                        {/* {stakedNfts?.map((nft) => (
                            <div className={styles.nftBox} key={nft.metadata.id.toString()}>
                                <ThirdwebNftMedia
                                    metadata={nft.metadata}
                                    className={styles.nftMedia}
                                />
                                <h3>{nft.metadata.name}</h3>
                                <button
                                    className={`${styles.mainButton} ${styles.spacerBottom}`}
                                    onClick={() => withdraw(nft.metadata.id)}
                                >
                                    Withdraw
                                </button>
                            </div>
                        ))} */}
                    </div>

                    {/* <hr className={`${styles.divider} ${styles.spacerTop}`} /> */}
                    <hr style={{ width: "80%", borderColor: "grey", opacity: "0.25" }} />
                    <p style={{ fontSize: 40, fontWeight: "bold" }}>Your Unstaked NFTs</p>
                    <hr style={{ width: "80%", borderColor: "grey", opacity: "0.25" }} />
                    <div >
                        {/* {ownedNfts?.map((nft) => (
                            <div className={styles.nftBox} key={nft.metadata.id.toString()}>
                                <ThirdwebNftMedia
                                    metadata={nft.metadata}
                                    className={styles.nftMedia}
                                />
                                <h3>{nft.metadata.name}</h3>
                                <button
                                    className={`${styles.mainButton} ${styles.spacerBottom}`}
                                    onClick={() => stakeNft(BigNumber.from(nft.metadata.id))}
                                >
                                    Stake
                                </button>
                            </div>
                        ))} */}
                    </div>
                </>
            )}


        </center>
    );
};

export default StackPage;
