import React, { Component } from 'react';
import { Button, Col, Form, Input, message, Modal, Popconfirm, Row, Space, Table, Upload } from 'antd';
import { FormInstance } from 'antd/lib/form';
import { DeleteOutlined, EditOutlined, LoadingOutlined, PlusOutlined, UploadOutlined } from '@ant-design/icons';
import firebase from '../../firebase';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import Column from 'antd/es/table/Column';
import { connect } from 'react-redux';
import { TableName } from '../../constants/TableName.constants';
import { IPeaceScript } from '../../interface/IPeaceScript.interface';

const firestore = firebase.firestore();
const storage = firebase.storage();
interface Props {
    navigate: ReturnType<typeof useNavigate>;
    user: any;
    isLogin: boolean;
    role: string
}

interface State {
    peaceScripts: IPeaceScript[];
    editing: boolean;
    selectedItem: IPeaceScript | null;
    loading: boolean;
    modalVisible: boolean;
    modalAction: string;
    imageUrl: string;
    uploading: boolean;
}

export class PreaceScriptOrderPage extends Component<Props, State> {
    private formRef = React.createRef<FormInstance>();
    constructor(props: Props) {
        super(props);
        this.state = {
            peaceScripts: [],
            editing: false,
            selectedItem: null,
            loading: false,
            modalVisible: false,
            modalAction: '',
            imageUrl: '',
            uploading: false,
        };
    }

    componentDidMount() {
        this.fetchProducts();
    }
    fetchProducts = () => {
        this.setState({ loading: true });
        const productsRef = firestore.collection(TableName.PeaceScripts);

        productsRef.onSnapshot((snapshot) => {
            const peaceScripts: IPeaceScript[] = [];
            snapshot.forEach((doc) => {
                const { name,
                    imageUrl,
                    description,
                    createdAt,
                    updatedAt,
                    createdBy,
                    status,
                } = doc.data()
                peaceScripts.push({
                    id: doc.id,
                    name,
                    imageUrl,
                    description,
                    createdAt,
                    updatedAt,
                    createdBy,
                    status
                });
            });

            this.setState({ peaceScripts, loading: false });
        });
    };



    handleAddItem = () => {
        this.formRef.current?.resetFields();

        this.setState({
            selectedItem: null,
            editing: false,
            modalVisible: true,
            modalAction: 'Add',
        });
    };

    handleEditItem = (product: IPeaceScript) => {
        this.setState({
            selectedItem: product,
            modalVisible: true,
            modalAction: 'Edit',
        });
    };

    handleFormSubmit = async (values: any) => {
        const { selectedItem, modalAction } = this.state;
        const { name,
            description,
            price, } = values
        const data: IPeaceScript = {
            name,
            description,
            createdBy: this.props.user.id,
            imageUrl: this.state.imageUrl,
            createdAt: new Date(),
            updatedAt: new Date(),
            status: "sell"
        };

        try {
            let ref;
            if (modalAction === 'Add') {
                ref = await firestore.collection(TableName.PeaceScripts).add(data);
                message.success('IPeaceScript added successfully');
            } else {
                if (selectedItem) {
                    await firestore.collection(TableName.PeaceScripts).doc(selectedItem.id).update(data);
                    message.success('IPeaceScript updated successfully');
                }

            }

            this.setState({ modalVisible: false, selectedItem: null });
        } catch (error) {
            message.error('Failed to add/update product');
        }
    };

    handleDeleteItem = async (id: string, imageUrl: string) => {
        try {
            // Delete product from Firestore
            await firestore.collection(TableName.PeaceScripts).doc(id).delete();

            // Delete image from Firebase Storage
            const storageRef = storage.refFromURL(imageUrl);
            await storageRef.delete();

            message.success('IPeaceScript deleted successfully');
        } catch (error) {
            message.error('Failed to delete product');
        }
    };

    handleUploadImage = async (options: any) => {
        console.log("start upload imange")
        const { file } = options;
        const { selectedItem } = this.state;
        const storageRef = storage.ref(`peaceScripts/${file.name}`);
        const uploadTask = storageRef.put(file);

        uploadTask.on(
            "state_changed",
            (snapshot) => {
                const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                console.log("Upload is " + progress + "% done");
                this.setState({ uploading: true });
            },
            (error) => {
                console.log(error);
            },
            () => {
                uploadTask.snapshot.ref.getDownloadURL().then(async (downloadURL) => {
                    console.log("File available at", downloadURL);
                    if (selectedItem) {
                        await firestore.collection(TableName.PeaceScripts).doc(selectedItem.id).update({
                            imageUrl: downloadURL
                        });
                    }
                    this.setState({ imageUrl: downloadURL, uploading: false });
                });
            }
        );
    };
    handleBeforeUpload = (file: any) => {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
            message.error('You can only upload JPG/PNG file!');
        }
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
            message.error('Image must smaller than 2MB!');
        }

        return isJpgOrPng && isLt2M;
    };
    render() {
        const { peaceScripts, loading, modalVisible, modalAction, selectedItem, uploading, imageUrl } = this.state;

        const uploadButton = (
            <div>
                {uploading ? <LoadingOutlined /> : <PlusOutlined />}
                <div style={{ marginTop: 8 }}>Upload</div>
            </div>
        );

        return (
            <div style={{ padding: 20 }}>
                <div style={{ justifyContent: 'flex-end', display: 'flex' }}>
                    <Button type="primary" onClick={this.handleAddItem}>
                        เพิ่มบท
                    </Button>
                </div>

                <Table dataSource={peaceScripts} loading={loading} rowKey="id" style={{ marginTop: 16 }}>
                    <Column title="Name" dataIndex="name" key="name" />
                    <Column title="Description" dataIndex="description" key="description" />
                    <Column
                        title="Image"
                        key="image"
                        render={(text, record: any) => (
                            <img src={record.imageUrl} alt="product image" style={{ width: 50, height: 50 }} />
                        )}
                    />
                    <Column
                        title="Action"
                        key="action"
                        render={(text, record: any) => (
                            <Space>
                                <Button type="primary" onClick={() => this.handleEditItem(record)}>
                                    <EditOutlined />
                                </Button>
                                <Popconfirm
                                    title="Are you sure delete this product?"
                                    onConfirm={() => this.handleDeleteItem(record.id, record.imageUrl)}
                                    okText="Yes"
                                    cancelText="No"
                                >
                                    <Button type="primary" danger>
                                        <DeleteOutlined />
                                    </Button>
                                </Popconfirm>
                            </Space>
                        )}
                    />
                </Table>
                <Modal
                    title={modalAction === 'Add' ? 'Add IPeaceScript' : 'Edit IPeaceScript'}
                    open={modalVisible}
                    footer={null}
                    onCancel={() => this.setState({ modalVisible: false, selectedItem: null, imageUrl: '', uploading: false })}
                >
                    <Form onFinish={this.handleFormSubmit} initialValues={selectedItem || undefined} ref={this.formRef}>
                        <Form.Item name="name" rules={[{ required: true, message: 'Please input product name!' }]} label="Name">
                            <Input />
                        </Form.Item>
                        <Form.Item name="description" label="Description">
                            <Input.TextArea />
                        </Form.Item>
                        <Form.Item label="Image">
                            <Upload
                                listType="picture-card"
                                showUploadList={false}
                                beforeUpload={this.handleBeforeUpload}
                                customRequest={this.handleUploadImage}
                                // onChange={this.handleImageChange}
                                disabled={uploading}
                            >
                                {imageUrl ? (
                                    <img src={imageUrl} alt="product" style={{ width: '100%' }} />
                                ) : (
                                    <div>
                                        {uploading ? <UploadOutlined /> : <PlusOutlined />}
                                        <div style={{ marginTop: 8 }}>Upload</div>
                                    </div>
                                )}
                            </Upload>
                        </Form.Item>
                        <Form.Item>
                            <Button type="primary" htmlType="submit" disabled={uploading}>
                                {modalAction === 'Add' ? 'Add' : 'Update'}
                            </Button>
                        </Form.Item>
                    </Form>
                </Modal>
            </div>
        )
    }
}
function withFormHOC(Component: React.ComponentType<Props>) {
    function UseForm(props: any) {
        const form = useForm({
            mode: 'onTouched',
        });
        const navigate = useNavigate()

        console.log(
            props
        )
        return <Component form={form} {...props}
            navigate={navigate}

        />
    }
    return UseForm;
}
const mapState = ({ AuthReducers, BCReducers }: any) => {
    return {
        isLogin: AuthReducers.isLogin,
        loading: AuthReducers.loading,
        user: AuthReducers.user,
    };
};
export default connect(mapState, {})(withFormHOC(PreaceScriptOrderPage))