import React, { Component } from 'react'
import { LaptopOutlined, NotificationOutlined, UserOutlined, UnorderedListOutlined } from '@ant-design/icons';
import type { MenuProps } from 'antd';
import { Breadcrumb, Layout, Menu, Button, Image, Row, Col } from 'antd';
import { themeColor } from '../assets/theme';
import { Link } from 'react-router-dom';
import styles from "../styles/Home.module.css";
import MCard from './../components/MCard';
import MCarousel from '../components/MCarousel';
import { PeaceRole } from '../data/PeaceRole';
import MEventCard from '../components/MEventCard';
import poster_metapeace_music_camp_1 from '../assets/image/poster_metapeace_music_camp_1-1.jpg';
import metapeace_festival from '../assets/image/metapeace_festival.jpg';
import peace_logo_1 from '../assets/image/peace_logo_1.jpg';

import { CalendarOutlined, EnvironmentOutlined, TeamOutlined } from '@ant-design/icons';
const { Header, Content, Footer, Sider } = Layout;

export class EventPage extends Component {
    render() {

        return (
            <div style={{ marginBottom: 64 }}>
                <center>
                    <MEventCard image={metapeace_festival}
                        title={"MetaPeace Festival บทที่ 2 การเดินทางแห่งจิต"}
                        subTitle={
                            <>
                                <div style={{ marginTop: 25, display: "flex", alignItems: "baseline" }}>
                                    <CalendarOutlined style={{ color: "white" }} />
                                    <p style={{ marginLeft: 5, fontSize: 18 }}>23 ธ.ค. 2566 - 24 ธ.ค. 2566</p>
                                </div>
                                <div style={{ display: "flex", alignItems: "baseline" }}>
                                    <EnvironmentOutlined style={{ color: "white" }} />
                                    <p style={{ marginLeft: 5, fontSize: 18 }}>ลานกางเต๊นท์วังกาฮุง</p>
                                </div>
                                <div style={{ display: "flex", alignItems: "baseline", height: 38 }}>
                                    <EnvironmentOutlined style={{ color: "white" }} />
                                    <a href='https://goo.gl/maps/q3uhQSot2PKLdsGu9' target={"_blank"} style={{ marginLeft: 5, fontSize: 18 }}>แผนที่ google map</a>
                                </div>
                                <div style={{ display: "flex", alignItems: "baseline", height: 38 }}>
                                    <TeamOutlined style={{ color: "white" }} />
                                    <p style={{ marginLeft: 5, fontSize: 18 }}>400</p>
                                </div>
                                <div style={{ display: "flex", }}>
                                    <p style={{ marginTop: 10, textAlign: "left" }}>
                                        ตัวตนของเราเป็นแบบไหน ตัวตนข้างในของเราคืออะไร มาร่วมสำรวจไปด้วยกัน การเดินแห่งจิต จะอธิบายตัวคุณเอง มาแชร์ประสบการณ์ รับความสนุก และ ปลดปล่อยไปกับเรา
                                        ด้วยบรรยากาศริมน้ำ เย็นสบาย ฟังเพลงสบายชิว ๆ พักกายพักใจ ทิ้งความเหนื่อย
                                        จากงาน ความเครียดจากชีวิต ดื่มด่ำกับช่วงเวลาไปกับเราได้แล้ววันนี้
                                    </p>
                                </div>
                            </>
                        }
                        subTitle2={
                            <>
                                <div>
                                    <p className="subTitle" >ซุ้มภายในงาน</p>
                                </div>
                                <div style={{ textAlign: "left" }}>
                                    <ul>
                                        <li>
                                            <p><b>Metapeace :</b> บอกเล่าเรื่องราว ตัวตน และอนาคต การเดินทางแห่งจิต</p>
                                        </li>
                                        <li>
                                            <p><b>Tech :</b> พูดคุยและเปลี่ยน เรื่องรางของ blockchain</p>
                                        </li>
                                        <li>
                                            <p><b>Phychedelic :</b> ค้นหาตัวตน แชร์ประสบการณ์ ปลดปล่อยชีวิต จิตบำบัด</p>
                                        </li>
                                        <li>
                                            <p><b>green zone :</b> พลังสีเขียว แลกเปลี่ยนข้อมูล</p>
                                        </li>
                                        <li>
                                            <p><b>เวทีกลาง :</b> ร่วมฟังเพลง ขับกล่อมยาวค่ำคืน</p>
                                        </li>
                                        <li>
                                            <p><b>เวทีรอง :</b> ร่วมเปิดโอกาสให้กับศิลปินหน้าใหม่</p>
                                        </li>
                                        <li>
                                            <p><b>วงธรรมชาติ :</b> รวมแชร์ประสบการณ์ชีวิต พูดคุยกับเพื่อนใหม่</p>
                                        </li>
                                    </ul>
                                </div>
                            </>
                        }
                        buttonLink="/nft/mint"
                    ></MEventCard>
                    <MEventCard image={poster_metapeace_music_camp_1}
                        close={true}
                        title={"MetaPeace music camp #1"}
                        subTitle={
                            <>
                                <div style={{ marginTop: 25, display: "flex", alignItems: "baseline" }}>
                                    <CalendarOutlined style={{ color: "white" }} />
                                    <p style={{ marginLeft: 5, fontSize: 18 }}>22 เม.ย. 2566 - 23 เม.ย. 2566</p>
                                </div>
                                <div style={{ display: "flex", alignItems: "baseline" }}>
                                    <EnvironmentOutlined style={{ color: "white" }} />
                                    <p style={{ marginLeft: 5, fontSize: 18 }}>ลานกางเต๊นท์วังกาฮุง</p>
                                </div>
                                <div style={{ display: "flex", alignItems: "baseline", height: 38 }}>
                                    <EnvironmentOutlined style={{ color: "white" }} />
                                    <a href='https://goo.gl/maps/q3uhQSot2PKLdsGu9' target={"_blank"} style={{ marginLeft: 5, fontSize: 18 }}>แผนที่ google map</a>
                                </div>
                                <div style={{ display: "flex", alignItems: "baseline", height: 38 }}>
                                    <TeamOutlined style={{ color: "white" }} />
                                    <p style={{ marginLeft: 5, fontSize: 18 }}>200</p>
                                </div>
                                <div style={{ display: "flex", }}>
                                    <p style={{ marginTop: 10, textAlign: "left" }}>
                                        มาเริ่มต้นเป็นชาว MetaPeace ได้แล้ววันนี้ สนุก และ ปลดปล่อยไปกับเรา ด้วยบรรยากาศริมน้ำ เย็นสบาย ฟังเพลงสบายชิว ๆ พักกายพักใจ ทิ้งความเหนื่อย
                                        จากงาน ความเครียดจากชีวิต ดื่มด่ำกับช่วงเวลาไปกับเราได้แล้ววันนี้ กับ MetaPeace music camp #1
                                    </p>
                                </div>
                            </>
                        }
                        subTitle2={
                            <>
                                <div>
                                    <p className="subTitle" >ซุ้มภายในงาน</p>
                                </div>
                                <div style={{ textAlign: "left" }}>
                                    <ul>
                                        <li>
                                            <p><b>Metapeace :</b> บอกเล่าเรื่องราว ตัวตน และอนาคต</p>
                                        </li>
                                        <li>
                                            <p><b>Tech :</b> พูดคุยและเปลี่ยน เรื่องรางของ blockchain</p>
                                        </li>
                                        <li>
                                            <p><b>จิตวิณณาญ :</b> ค้นหาตัวตน แชร์ประสบการณ์ ปลดปล่อยชีวิต</p>
                                        </li>
                                        <li>
                                            <p><b>green zone :</b> พลังสีเขียว แลกเปลี่ยนข้อมูล</p>
                                        </li>
                                        <li>
                                            <p><b>เวทีกลาง :</b> ร่วมฟังเพลง ขับกล่อมยาวค่ำคืน</p>
                                        </li>
                                    </ul>
                                </div>
                            </>
                        }
                        buttonLink="/nft/mint"
                    ></MEventCard>
                </center>

            </div >
        )
    }

}

export default EventPage