import React, { Component } from 'react'
import { LaptopOutlined, NotificationOutlined, UserOutlined, UnorderedListOutlined } from '@ant-design/icons';
import type { MenuProps } from 'antd';
import { Breadcrumb, Layout, Menu, Button, Image, Row, Col } from 'antd';
import { themeColor } from '../assets/theme';
import { Link } from 'react-router-dom';
import styles from "../styles/Home.module.css";
import MCard from './../components/MCard';
import MCarousel from '../components/MCarousel';
import { PeaceRole } from '../data/PeaceRole';
interface listData {
    name: string,
    detail: string
}
const { Header, Content, Footer, Sider } = Layout;

export class DocsPage extends Component {
    render() {
        const businessModels: listData[] = [
            { name: "Play", detail: "Movie / Music / Game / Game Show / Concert / Concert MusicFestival" },
            { name: "Social", detail: "มีกิจกรรมต่างๆ เพื่อแสดงออกถึงตัวตนของเราผ่านทางสังคมในโลก metaverse มีการ vote เลือกหรือเสนอนโยบาย เพื่อให้ community นั้นได้ขับเคลื่อนไปข้างหน้าได้อย่างยั่งยืน" },
            { name: "Shops", detail: "โลกใบใหม่ของนักช๊อปดิจิทัลผ่าน Marketplaces ที่สามารถประมูลซื้อขายแลกเปลี่ยนสินค้า NFts ,และยังสามารถสั่งซื้อสินค้าในโลกความจริงได้หากคุณต้องการ" },
            { name: "Work Space", detail: "ทุกคนสามารถสร้าง Studio ทำงานร่วมกับเพื่อนๆผ่าน Project ที่มี contact และฟังชั่นต่างๆเป็น Tool ช่วยลดขั้นตอนในการทำงานให้สั้นลงเพื่อให้เรามีเวลาได้ใช้ชีวิตมากขึ้น" },
            { name: "Learn", detail: "พื้นที่เรียนรู้ของนักคิด และ นักสร้างสรรค์ทั้งผู้สอนและผู้เรียนจะได้รับเหรียญเป็นการตอบแทน" },
            { name: "City Land", detail: "เป็นพื้นที่ที่มี AI ตัวละครจากหนังหรือซีรีส์เรื่องต่างๆในระบบ มาใช้ชีวิตอยู่ใน land คอยให้บริการ User ที่เข้ามาใช้งาน โดยการกำหนดหน้าที่ต่างๆให้กับ Ai" },
        ]
        const roladMaps = [
            { name: "2023", detail: "Peace Script" },
            { name: "2024", detail: "ระดมทุน ICO , Experience / VR AR XR 3D , AI" },
            { name: "2025", detail: "Value/blockchain smart contract,NFT,DEFI,LAND,MARKETPLACE,PEACE DAO" },
            { name: "2026", detail: "Test System" },
        ]
        return (
            <div style={{ marginBottom: 64 }}>
                <center>
                    <Row style={{ padding: 30, justifyContent: "center" }} align={"bottom"}>
                        <Col>
                            <Image preview={false} style={{ width: 50 }} src="https://bafkreihnumiaieoepdkfo7spul24rbvc276uepasuyoo6ivnsjt2ibtyly.ipfs.nftstorage.link" alt="mp1" />
                        </Col>
                        <Col>
                            <Image preview={false} style={{ width: 40 }} src="https://bafkreidc5rppmki6brph6azojmmidtbve5h7d4ar6pr2y7gnc6grahrtka.ipfs.nftstorage.link" alt="mp2" />
                        </Col>
                    </Row>
                    <MCard
                        title='Welcome to MetaPeace'
                        subTitle='WEB 3.0 / DApp / DAO / Metaverse / Version 0.0.1'
                    >
                        <p className="detail">อิสรภาพของการสร้างสรรค์งานในรูปแบบต่างๆที่ทำงานได้ทั้งในโลกจริงและโลกเสมือน พร้อมทั้งเป็นแหล่งรวมความบันเทิงหลากหลายรูปแบบเช่น ดูหนัง ฟังเพลง
                            concert music festival เล่นเกมส์ เกมส์โชว์  Social Media มี Marketplace ให้ทุกคนสามารถซื้อขายแลกเปลี่ยน NFTs พร้อมสั่งซื้อสิ้นค้าในโลกความเป็นจริงได้อย่างสะดวกสบาย เป็นห้องเรียนรู้ที่ผู้สอนและผู้เรียนจะได้เหรียญเป็นการตอบแทน
                            มี City land ที่มี AI ตัวละครจากหนังหรือซีรีส์เรื่องต่างๆในระบบมาอาศัยอยู่ รอต้อนรับ User ที่เป็นแฟนคลับเข้ามาเยือน  เพื่อให้ทุกคนได้ผ่อนคลายและเพลิดเพลินไปกับช่วงเวลาที่มีอย่างจำกัดในแต่ละวันให้คุ่มค่าที่สุด
                            พร้อมกับการขับเคลื่อนชุมชนด้วยระบบ DAO
                        </p>
                        <div>
                            <Button type="primary" href="https://metapeace.gitbook.io/docs">Learn more</Button>
                        </div>
                    </MCard >
                    <div id="youtube" style={{ maxWidth: 560, height: 315 }}>
                        <iframe
                            title="YouTube Video"
                            width="100%"
                            height="100%"
                            src="https://www.youtube.com/embed/5wAdrV_I3AU"
                            frameBorder="0"
                            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen

                        />
                    </div>

                    <div >
                        <p className="title">Business Model</p>
                    </div>
                    <MCarousel
                        list={businessModels}
                    >
                    </MCarousel>
                    <MCard
                        title='Vision'>
                        <p className="detail">การสร้าง Metaverse ที่เป็นโครงสร้างพื้นฐานในระบบนิเวศน์การทำงานรูปแบบ Project โดยเน้นไปที่ Entertainment ที่คำนึงถึง stakeholder ในทุกๆส่วน พร้อมการขับเคลื่อนชุมชนด้วย DAO เพื่อให้เกิดความยั่งยืน ไร้ขีดจำกัดของการสร้างสรรค์งาน
                        </p>
                    </MCard>

                    <MCard
                        title='Mission'>
                        <p className="detail">การแก้ปัญหา ขีดจำกัดของการสร้างงานให้สามารถเข้าถึงแหล่งเงินทุน โดยที่ทุกคนมีส่วนร่วมในความเป็นเจ้าของ มีจุดเด่นอยู่ที่ ฟังชั่นการเขียนบทที่ไม่ว่าใครก็สามารถเขียนเรื่องราวได้
                        </p>
                    </MCard>

                    <MCard
                        title='Peace Core'>
                        <p className="detail">
                            ฟังชั่นการเขียนบท การสร้างตัวละครที่ง่ายในการใช้งานใครๆก็สามารถเขียนเรื่องราวเป็นของตัวเองได้ ด้วยฟังชั่น Peace Script ที่สามารถเก็บข้อมูลได้ลึกถึงระดับจิตไต้สำนึก อารมณ์ ลักษณะนิสัยใจคอ ความชอบ ทัศนคติ มุมมองความคิด ความเชื่อ ที่ระบุได้ระเอียดถึงแต่ละสถานการณ์ต่างๆที่เกิดขึ้นในแต่ละฉากนั้นๆ
                        </p>
                    </MCard>
                    <MCard
                        title='Overview structure Core'>

                        <Image style={{ borderRadius: 10 }} src="https://bafybeidwjiovjm5xr3672giikpbfliqpg5brrxkdl2osyiaorbj3plerkm.ipfs.nftstorage.link" alt="peaceCore" />

                    </MCard>
                    <div>
                        <p className="title">Road Map</p>
                    </div>
                    <MCarousel
                        list={roladMaps}
                    >
                    </MCarousel>
                    <Row style={{ width: "100%", padding: 20, backgroundColor: themeColor.navbar, justifyContent: 'center', alignItems: 'center', marginTop: 20 }}>
                        <Image width={64} src="https://gateway.pinata.cloud/ipfs/QmZPU2ufbP1vPvhCzwVN4D4JEv8vb1WeigdhFrwJV8xE1z/model-peace-robot.gif" alt="iconPeaceRobot" />
                        <div style={{ marginLeft: 20 }}>
                            <p style={{ fontSize: 64, fontWeight: "bold", marginBottom: 0 }}>NFTs Peace Robot</p>
                        </div>
                    </Row>
                    <MCard>
                        <p className='title'>
                            คุณสมบัติ
                        </p>
                        <div style={{ marginTop: 10, textAlign: "left" }}>
                            {PeaceRole.map((item, i) =>
                                <p key={i}>{i + 1}. {item}</p>
                            )}
                        </div>

                    </MCard>
                    <Row style={{ justifyContent: 'center' }}>
                        <Col style={{
                            border: '1px solid #fff',
                            borderRadius: 10,
                            flexDirection: 'column',
                            padding: 10,
                            width: 400
                        }}>
                            <Image src={`https://gateway.pinata.cloud/ipfs/QmZPU2ufbP1vPvhCzwVN4D4JEv8vb1WeigdhFrwJV8xE1z/box%20peace%2004.gif`} alt="dropRobot" />

                            <Link to="/nft/mint">
                                <Button style={{ marginTop: 10, height: 64 }} shape="round" size="large" type="primary" ><p style={{ fontSize: 24, marginBottom: 0 }}>Peace Robot</p></Button>
                            </Link>
                            <p style={{ fontSize: 20 }}>
                                Use the NFT Drop Contract to claim an NFT from the collection.
                            </p>
                        </Col>
                        <Col style={{
                            border: '1px solid #fff',
                            borderRadius: 10,
                            flexDirection: 'column',
                            padding: 10,
                            width: 400
                        }}>
                            <Image src={`https://gateway.pinata.cloud/ipfs/QmZPU2ufbP1vPvhCzwVN4D4JEv8vb1WeigdhFrwJV8xE1z/icon-nfts-staking04.gif`} alt="stacking" />
                            <Link to="/stake">
                                <Button style={{ marginTop: 10, height: 64 }} shape="round" size="large" type="primary" ><p style={{ fontSize: 24, marginBottom: 0 }}>Staking NFT</p></Button>
                            </Link>
                            <p style={{ fontSize: 20 }}>
                                Use staking contracts to bet your NFT to earn <b>Token</b>.
                            </p>
                        </Col>
                    </Row>
                </center>

            </div >
        )
    }

}

export default DocsPage