import React from "react";
import { Form, Input, Button, message, List, Avatar, Image, Upload, Modal } from "antd";
import firebase from "../../firebase";
import { useLocation, useNavigate } from "react-router-dom";
import { useForm } from "antd/es/form/Form";
import { IProduct } from "../../interface/data/IProduct.interface";
import { PlusOutlined, UploadOutlined } from "@ant-design/icons";
import { connect } from "react-redux";
const auth = firebase.auth();
const firestore = firebase.firestore();
const storage = firebase.storage();
interface Props {
    form: any
    navigate: ReturnType<typeof useNavigate>
    location: ReturnType<typeof useLocation>
    user: any
}
interface Buyer {
    name: string,
    productId: string,
    userId: string,
    purchaseDate: Date,
    status: 'จ่ายแล้ว' | 'รอชำระเงิน'
    paymentSlipUrl: string,
}


interface State {
    loading: boolean;
    products: IProduct[],
    buyModalVisible: boolean,
    uploading: boolean;
    buyer: Buyer
}

class ProducsPage extends React.Component<Props, State> {
    unsubscribe = () => { };
    constructor(props: Props) {
        super(props)
        this.state = {
            loading: false,
            products: [],
            buyModalVisible: false,
            uploading: false,
            buyer: {
                name: '',
                productId: '',
                userId: '',
                purchaseDate: new Date(),
                status: 'รอชำระเงิน',
                paymentSlipUrl: '',
            }
        }

    }

    componentDidMount(): void {
        // auth.onAuthStateChanged((user) => {

        //     if (!user) {
        //         this.props.navigate('/member/login', {
        //             state: {
        //                 redirect: this.props.location.pathname
        //             }
        //         })
        //     } else {
        //         console.log(user.uid)
        //         this.unsubscribe = firestore.collection('products').onSnapshot(snapshot => {
        //             const productsData = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })) as IProduct[];
        //             this.setState({ products: productsData });
        //         });
        //     }
        // });
    }

    componentWillUnmount() {
        this.unsubscribe();
    }
    handleCancel = () => {
        this.setState({
            buyModalVisible: false
        })
    }
    handleUploadImage = async (options: any) => {
        console.log("start upload imange")
        const { file } = options;
        const storageRef = storage.ref(`purchases/${file.name}`);
        const uploadTask = storageRef.put(file);

        uploadTask.on(
            "state_changed",
            (snapshot) => {
                const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                console.log("Upload is " + progress + "% done");
                this.setState({ uploading: true });
            },
            (error) => {
                console.log(error);
            },
            () => {
                uploadTask.snapshot.ref.getDownloadURL().then(async (downloadURL) => {
                    console.log("File available at", downloadURL);
                    this.setState({ buyer: { ...this.state.buyer, paymentSlipUrl: downloadURL }, uploading: false });
                });
            }
        );
    };
    handleBeforeUpload = (file: any) => {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
            message.error('You can only upload JPG/PNG file!');
        }
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
            message.error('Image must smaller than 2MB!');
        }

        return isJpgOrPng && isLt2M;
    };
    handleSubmit = async () => {
        const purchasesRef = firestore.collection('purchases');
        const { buyer: { name, paymentSlipUrl, productId, purchaseDate, status, userId } } = this.state
        const purchase = {
            name,
            productId,
            userId: this.props.user.uid,
            purchaseDate: Date.now(),
            status: paymentSlipUrl ? 'จ่ายแล้ว' : 'รอชำระเงิน',
            paymentSlipUrl,
        };
        await purchasesRef.add(purchase).then(() => {
            message.success("buy product success")
        }).catch((error) => {
            console.log(error)
            message.error("failed to buy product")
        })
    }
    render() {
        const { loading, products, uploading, } = this.state;

        return (
            <div style={{ padding: 20 }}>
                <div className="bg-gray-200 py-8">
                    <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
                        <p className="text-2xl font-bold mb-4">สินค้าทั้งหมด</p>
                        <List
                            itemLayout="horizontal"
                            dataSource={products}
                            style={{ backgroundColor: '#c0c0c0', height: 400 }}
                            renderItem={(product) => {
                                return (
                                    <List.Item>
                                        <List.Item.Meta
                                            avatar={<Image width={200} src={product.imageUrl} />}
                                            title={<a href={`/product/${product.id}`}>{product.name}</a>}
                                            description={product.description}
                                        />
                                        <div className="flex flex-col justify-between">
                                            <div className="text-2xl font-bold">{product.price.toLocaleString()} บาท</div>
                                            <Button type="primary" size="large" block onClick={() => this.setState({ buyModalVisible: true, buyer: { ...this.state.buyer, productId: product.id ? product.id : "" } })}>
                                                สั่งซื้อสินค้า
                                            </Button>
                                        </div>
                                    </List.Item>
                                );
                            }}
                        />
                        <Modal
                            title="สั่งซื้อสินค้า"
                            open={this.state.buyModalVisible}
                            onCancel={this.handleCancel}
                            footer={[
                                <Button key="cancel" onClick={this.handleCancel}>
                                    ยกเลิก
                                </Button>,
                                <Button key="submit" type="primary" onClick={this.handleSubmit}>
                                    สั่งซื้อ
                                </Button>,
                            ]}
                        >
                            <Form layout="vertical">
                                <Form.Item label="ชื่อผู้ซื้อ" required>
                                    <Input value={this.state.buyer.name} name="name" onChange={(e) => this.setState({ buyer: { ...this.state.buyer, name: e.target.value } })} />
                                </Form.Item>
                                <Form.Item label="ภาพสลิปการโอนเงิน" >
                                    <Upload
                                        listType="picture-card"
                                        showUploadList={false}
                                        beforeUpload={this.handleBeforeUpload}
                                        customRequest={this.handleUploadImage}
                                        // onChange={this.handleImageChange}
                                        disabled={uploading}
                                    >
                                        {this.state.buyer.paymentSlipUrl ? (
                                            <img src={this.state.buyer.paymentSlipUrl} alt="product" style={{ width: '100%' }} />
                                        ) : (
                                            <div>
                                                {uploading ? <UploadOutlined /> : <PlusOutlined />}
                                                <div style={{ marginTop: 8 }}>Upload</div>
                                            </div>
                                        )}
                                    </Upload>
                                </Form.Item>
                            </Form>
                        </Modal>
                    </div>
                </div>
            </div>

        );
    }
}
const mapState = ({ AuthReducers }: any) => {
    return {
        ...AuthReducers
    };
};
function withFormHOC(Component: React.ComponentType<Props>) {
    function UseForm(props: any) {
        const form = useForm();
        const navigate = useNavigate()
        const location = useLocation();
        return <Component form={form} {...props}
            navigate={navigate}
            location={location}

        />
    }
    return UseForm;
}




export default connect(mapState, {})(withFormHOC(ProducsPage))
