import { Button } from "antd";
import "../styles/CustomButton.styles.css"
interface Props {
    children?: React.ReactNode;
    style?: React.CSSProperties;
    onClick?: Function;
    loading?: boolean;
    disabled?: boolean;
    size?: "small" | "middle" | "large";

}

const MButton: React.FC<Props> = ({ style, children, onClick, loading, disabled, size = "middle" }) => {
    return <Button
        // className="custom-button"
        loading={loading}
        disabled={loading || disabled}
        shape="round" size={size} type="primary"
        style={{
            ...style,
            //  background: 'linear-gradient(to right, #00b0ff, #E55D87) !important',
        }}
        onClick={() => onClick && onClick()}
    >
        {children}
    </Button>

}

export default MButton;