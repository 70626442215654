export function hideSomeText(text: string | undefined) {

    if (text === undefined) {
        return
    } else {
        const fontText = text.substring(0, 5);
        const backText = text.substring(text.length - 4, text.length);

        return fontText + "..." + backText
    }

}
