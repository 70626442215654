
import { themeColor } from './../assets/theme';

import { Col, Row, Image, Button } from 'antd';
import { randomBytes } from 'crypto';

import { Link } from 'react-router-dom';

interface Props {
    children?: React.ReactNode;
    style?: React.CSSProperties;
    image?: string
    title?: string;
    subTitle?: any;
    subTitle2?: any;
    close?: boolean;
    buttonName?: string;
    buttonLink?: string;
}
const styles = {
    image: {
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
    },

}

const MEventCard: React.FC<Props> = ({ style, title, subTitle, subTitle2, image, close = false, buttonName = "ซื้อบัตร", buttonLink }) => {

    const imageWidth = window.innerWidth > 500 ? 315 : "auto";
    return <div style={{
        ...style,
        margin: 20,
        borderWidth: "1px solid #999",
        backgroundColor: 'rgba(128, 128, 128, 0.2)',
        borderRadius: 10,
        padding: "5px 5px 10px 10px",
    }}>

        <div style={{
            // backgroundColor: 'rgba(85, 92, 136, 0.8)',
            width: '90%',
            zIndex: 3,
            position: "relative",
            flexFlow: "unset",

        }}
        >
            <Row style={{ justifyContent: 'center' }}>
                <Col flex={"none"}>
                    <div style={{
                        height: 395, width: 300,
                        backgroundPosition: 'center',
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: 'cover',
                    }}>
                        <div style={{
                            height: "100%",
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}>
                            <Image src={image}
                                preview={!close}
                                style={{
                                    height: "auto", width: "100%",
                                    paddingTop: 15,
                                    maxWidth: imageWidth
                                }} ></Image>
                        </div>

                        {close &&
                            <p style={{
                                position: 'absolute',
                                color: "white",
                                backgroundColor: "red", fontSize: 32,
                                top: "50%", left: "50%",
                                fontWeight: "bold",
                                padding: "10px 20px",
                                transform: " translate(-50%, -50%)"
                            }}>Finish</p>
                        }

                    </div>
                </Col>
                <Col flex={"auto"} style={{ padding: 20, maxWidth: 315 }}>
                    <div>
                        <p className="title">{title}</p>
                    </div>
                    {subTitle}
                </Col>
                <Col flex={"auto"} style={{ padding: 20, maxWidth: 315 }}>
                    {subTitle2}



                </Col>
            </Row>
            {!close && buttonLink &&
                <Row style={{ justifyContent: 'center' }}>
                    <Link to={buttonLink}>
                        <Button style={{ marginTop: 10, height: 64 }} shape="round" size="large" type="primary" ><p style={{ fontSize: 24, marginBottom: 0 }}>{buttonName}</p></Button>
                    </Link>
                </Row>
            }

        </div>



    </div >
}

export default MEventCard;