
import { themeColor } from './../assets/theme';
interface Props {
    children?: React.ReactNode;
    style?: React.CSSProperties;
    title?: string;
    subTitle?: string;
}
const MCard: React.FC<Props> = ({ style, children, title, subTitle }) => {
    return <div style={{ marginBottom: 20, maxWidth: 680, ...style }}>
        <p className="title">{title}</p>
        <p className="subTitle"> {subTitle}</p>
        <div style={{
            padding: 20,
            backgroundColor: themeColor.navbar,
            borderRadius: 10,
            boxShadow: "3px 3px 7px 1px rgb(119 196 255 / 20%)",

        }}
        >
            {children}
        </div>
    </div>
}

export default MCard;