import {
  ThirdwebNftMedia,
  useAddress,
  useMetamask,
  useTokenBalance,
  useOwnedNFTs,
  useContract,
  ConnectWallet,
  useClaimedNFTs,
  useNetwork,
  useNFTs,
} from "@thirdweb-dev/react";


import MButton from "../components/MButton";
import { hideSomeText } from './../methods/logics';
import PeaceNFT from './../components/PeaceNFT';
import { Button, Col, Row, Spin } from "antd";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import React, { Component } from 'react'
import { ChainId, NFT } from "@thirdweb-dev/sdk";
import MyNFTs from "./NFTs/MyNfts";
import { connect } from "react-redux";

interface Props {
  form: ReturnType<typeof useForm>;
  navigate: ReturnType<typeof useNavigate>
  address: ReturnType<typeof useAddress>
  connectWithMetamask: ReturnType<typeof useMetamask>
  isLoading: boolean;
  switchNetwork: Function,
  nftAddress: string
}
interface State {
  loading: boolean,

}


export class CollectionPage extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      loading: false,
    }

  }
  componentDidMount(): void {
  }
  render() {
    const { } = this.state
    const { connectWithMetamask, address } = this.props;
    return (
      <center >
        <div style={{ marginTop: 84 }}>
          <p className="title">Collection PEACE Robot Little</p>
          <p className="subtitle">{hideSomeText(this.props.nftAddress)}</p>



          <hr style={{ width: "80%", borderColor: "grey", opacity: "0.25" }} />

          {!address ? (
            <MButton onClick={() => connectWithMetamask()}>
              Connect Wallet
            </MButton>
          ) : (
            <>
              <Row style={{ justifyContent: "center" }}>
                <p className="subtitle">Your Address</p>
                <p>{hideSomeText(address)}</p>

              </Row>

              {/* <div style={{
                border: '1px solid grey',
                borderRadius: 10,
                flexDirection: 'column',
                padding: 10,
                width: 400,
                margin: 10
              }}>
                <div>
                  <p className="subTitle">Current Balance</p>
                </div>
                <div>
                  <p className="subTitle">
                    <b>{tokenBalance?.displayValue}</b> {tokenBalance?.symbol}
                  </p>
                </div>
              </div> */}

              {/* <hr style={{ width: "80%", borderColor: "grey", opacity: "0.25" }} /> */}

              {/* <p className="subTitle">My NFTs Collection</p> */}

              {this.props.isLoading ?
                <Row style={{ justifyContent: 'center' }} >
                  <Spin tip="Loading" size="large" >

                  </Spin>
                </Row>

                :
                <Row style={{ justifyContent: 'center' }} >

                  <MyNFTs address={address} nftAddress={this.props.nftAddress} />
                  {/* <MyNFTs></MyNFTs> */}
                </Row>
              }
            </>
          )}
          <hr style={{ width: "80%", borderColor: "grey", opacity: "0.25" }} />
        </div>
      </center>
    )
  }
}






// useEffect(() => {
//   console.log("nfts", ownedNfts)
// })
// Load Balance of Token
// const { data: tokenBalance } = useTokenBalance(tokenContract, address);

///////////////////////////////////////////////////////////////////////////
// Custom contract functions
///////////////////////////////////////////////////////////////////////////
// const [stakedNfts, setStakedNfts] = useState<any[]>([]);
// const [claimableRewards, setClaimableRewards] = useState<BigNumber>();

// useEffect(() => {
//   if (!contract) return;

//   async function loadStakedNfts() {
//     const stakedTokens = await contract?.call("getStakedTokens", address);

//     // For each staked token, fetch it from the sdk
//     const stakedNfts = await Promise.all(
//       stakedTokens?.map(
//         async (stakedToken: { staker: string; tokenId: BigNumber }) => {
//           const nft = await nftDropContract?.get(stakedToken.tokenId);
//           return nft;
//         }
//       )
//     );

//     setStakedNfts(stakedNfts);
//     console.log("setStakedNfts", stakedNfts);
//   }

//   if (address) {
//     loadStakedNfts();
//   }
// }, [address, contract, nftDropContract]);

// useEffect(() => {
//   if (!contract || !address) return;

//   async function loadClaimableRewards() {
//     const cr = await contract?.call("availableRewards", address);
//     console.log("Loaded claimable rewards", cr);
//     setClaimableRewards(cr);
//   }

//   loadClaimableRewards();
// }, [address, contract]);

///////////////////////////////////////////////////////////////////////////
// Write Functions
///////////////////////////////////////////////////////////////////////////
// async function stakeNft(id: BigNumber) {
//   if (!address) return;

//   const isApproved = await nftDropContract?.isApproved(
//     address,
//     stakingContractAddress
//   );
//   // If not approved, request approval
//   if (!isApproved) {
//     await nftDropContract?.setApprovalForAll(stakingContractAddress, true);
//   }
//   const stake = await contract?.call("stake", id);
// }

// async function withdraw(id: BigNumber) {
//   const withdraw = await contract?.call("withdraw", id);
// }

// async function claimRewards() {
//   const claim = await contract?.call("claimRewards");
// }

// if (isLoading) {
//   return <div>Loading</div>;
// }

// return (

// );


// };
function withFormHOC(Component: React.ComponentType<Props>) {
  function UseForm(props: any) {
    const form = useForm({
      mode: 'onTouched',
    });
    const navigate = useNavigate()
    const address = useAddress();
    const connectWithMetamask = useMetamask();
    const [{ data, }, switchNetwork] = useNetwork();
    return <Component form={form} {...props}
      navigate={navigate}
      address={address}
      connectWithMetamask={connectWithMetamask}
      isLoading={false}
      switchNetwork={switchNetwork}
    />
  }
  return UseForm;
}
const mapState = ({ AuthReducers, BCReducers }: any) => {
  return {
    isLogin: AuthReducers.isLogin,
    loading: AuthReducers.loading,
    user: AuthReducers.user,
    nftAddress: BCReducers.nftAddress
  };
};
export default connect(mapState, {})(withFormHOC(CollectionPage))



