import { Component } from 'react'
import { BrowserRouter, Navigate, Route, useNavigate } from 'react-router-dom'
import { Routes, } from 'react-router-dom'
import AppLayout from '../layouts/AppLayout'
import { connect } from 'react-redux'
import firebase from '../firebase'
import AuthActions from '../reduxs/auth/AuthActions'
// pages

import MintPage from '../pages/MintPage'
import CollectionPage from './../pages/CollectionPage';
import MarketplacePage from './../pages/MarketplacePage';
import AboutPage from '../pages/AboutPage'
import EventPage from '../pages/EventPage'
import HomePage from '../pages/HomePage'
import StakePage from '../pages/StakePage'
import ManageProductPage from '../pages/admin/ManageProductPage'
import DashboardPage from '../pages/admin/DashboardPage'
import LoginPage from '../pages/auth/LoginPage';
import ProductManagePage from './../pages/admin/ProductManagePage';
import ProductsPage from '../pages/products/ProductsPage';
import MemberLoginPage from '../pages/members/MemberLoginPage'
import DocsPage from '../pages/DocsPage'
import { RoleName } from '../constants/RoleName.constants'
import PeaceBotPage from '../pages/PeaceBotPage'
import PeaceScriptOrderPage from '../pages/peaceScript/PeaceScriptOrderPage'
import MyOrderPeaceScriptPage from '../pages/peaceScript/MyOrderPeaceScriptPage'
import PeaceScriptShopPage from '../pages/peaceScript/PeaceScriptShopPage'
import { IUser } from '../interface/data/IUser.interface'
import { TableName } from '../constants/TableName.constants'
import BCActions from '../reduxs/blockchain/BCActions'
import AdminLogin from '../pages/admin/AdminLogin'

const auth = firebase.auth();
const firestore = firebase.firestore();

interface Props {
    isLogin: boolean,
    user: IUser,
    setAuth: Function,
    setAddress: Function,
}
interface State {

}
const manage = "manage";
export class RootRoute extends Component<Props, State> {
    constructor(props: Props) {
        super(props)
    }
    componentDidMount(): void {
        if (process.env.NODE_ENV === "development") {
            firestore.collection(TableName.Configs).doc("testContractAddress").get().then((addressDov) => {
                const addressData = addressDov.data()

                if (addressData) {
                    console.log("test", addressData.nftAddress)
                    this.props.setAddress({
                        nftAddress: addressData.nftAddress,
                        tokenAddress: addressData.tokenAddress,
                        stakingAddress: addressData.stakingAddress,
                    })

                }
            })
        } else {
            firestore.collection(TableName.Configs).doc("mainContractAddress").get().then((addressDov) => {
                const addressData = addressDov.data()
                if (addressData) {
                    console.log("main", addressData.nftAddress)
                    this.props.setAddress({
                        nftAddress: addressData.nftAddress,
                        tokenAddress: addressData.tokenAddress,
                        stakingAddress: addressData.stakingAddress,
                    })
                }
            })
        }
    }
    render() {
        console.log(this.props.isLogin)
        return (
            <BrowserRouter>
                {/* {this.props.isLogin ? */}

                <AppLayout
                    content={
                        <Routes>

                            <Route path="/" element={<Navigate to={"/docs"} />}></Route>
                            <Route path="*" element={<Navigate to={"/docs"} />}></Route>
                            <Route path="/home" element={<HomePage />}></Route>
                            <Route path="/docs" element={<DocsPage />}></Route>
                            <Route path="/events" element={<EventPage />}></Route>
                            <Route path="/about" element={<AboutPage />}></Route>
                            <Route path="/products" element={<ProductsPage />}></Route>
                            <Route path="/peace-script-shop" element={<PeaceScriptShopPage />}></Route>
                            <Route path="/peace-script-my-order" element={<MyOrderPeaceScriptPage />}></Route>
                            <Route path="/peace-bot" element={<PeaceBotPage />}></Route>
                            {/* {this.props.isLogin &&
                                <> */}
                            {/* nft */}
                            <Route path="/nft/stake" element={<StakePage />}></Route>
                            <Route path="/nft/mint" element={<MintPage />}></Route>
                            <Route path="/nft/collection" element={<CollectionPage />}></Route>
                            <Route path="/marketplace" element={<MarketplacePage />}></Route>
                            <Route path="/peace-bot" element={<PeaceBotPage />}></Route>
                            <Route path="/admin/login" element={<AdminLogin />}></Route>
                            <Route path="/admin" element={<AdminLogin />}></Route>
                            {/* nft */}
                            {/* </>

                            } */}

                            {/* admin manage*/}
                            {this.props.isLogin && this.props.user.role === RoleName.Admin &&
                                <>
                                    <Route path={manage + "/admin/dashboard"} element={<DashboardPage />}></Route>
                                    <Route path={manage + "/admin/products"} element={<ProductManagePage />}></Route>
                                    <Route path={manage + "/admin/peace-script-order"} element={<PeaceScriptOrderPage />}></Route>

                                </>
                            }
                        </Routes>
                    }>

                </AppLayout >
                {/* :
                    <Routes>
                        <Route path="/" element={<Navigate to={"/login"} />}></Route>
                        <Route path="*" element={<Navigate to={"/login"} />}></Route>
                        <Route path="/login" element={<LoginPage />}></Route>
                    </Routes>
                } */}

            </BrowserRouter>
        )




    }
}
const { setAuth } = AuthActions
const { setAddress } = BCActions
const mapState = ({ AuthReducers }: any) => {
    return {
        isLogin: AuthReducers.isLogin,
        user: AuthReducers.user
    };
};

export default connect(mapState, { setAuth, setAddress })(RootRoute)
