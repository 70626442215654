import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import { getAnalytics } from "firebase/analytics";
import "firebase/database"; // If using Firebase database
import "firebase/compat/storage"; // If using Firebase storage

import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
    apiKey: "AIzaSyCx88s8HZmAG3WKlz5wihdk4MI9_w1wY4g",
    authDomain: "meta-peace.firebaseapp.com",
    projectId: "meta-peace",
    storageBucket: "meta-peace.appspot.com",
    messagingSenderId: "186051280910",
    appId: "1:186051280910:web:24fefbf3c897cbef023c5f",
    measurementId: "G-RPRM33BKJB"
};

const app = firebase.initializeApp(firebaseConfig);
const analytics = getAnalytics(firebase.initializeApp(firebaseConfig));
// export const db = getFirestore(app)
export { firebase as default };
