
import { Modal, Image } from 'antd';
import { themeColor } from './../assets/theme';
import { useState } from 'react';
import metamask_logo from '../assets/image/metamask_logo.png';
interface Props {
    children?: React.ReactNode;
    style?: React.CSSProperties;
    handleOk: any;
    open: boolean;
    okText: string;
}
const ModalConnectMetamask: React.FC<Props> = ({ style, children, handleOk, open, okText, }) => {
    const [visible, setVisible] = useState(open)
    return <Modal
        style={{ ...style }}
        title="คุณจำเป็นต้องติดตั้ง Metamask ก่อน"
        open={visible}
        onOk={handleOk}
        okText={okText}
        onCancel={() => {
            setVisible(false)
        }}
    >
        <Image preview={false} src={metamask_logo} height={100}></Image>
    </Modal>
}

export default ModalConnectMetamask;