
interface MAddress {
    nftAddress: string;
    tokenAddress: string;
    stakingAddress: string;
}

const BCActions = {


    SET_ADDRESS_REQUEST: 'SET_ADDRESS_REQUEST',
    SET_ADDRESS_SUCCESS: 'SET_ADDRESS_SUCCESS',
    SET_ADDRESS_ERROR: 'SET_ADDRESS_ERROR',


    setAddress: (props: MAddress) => ({
        type: BCActions.SET_ADDRESS_REQUEST,
        props
    }),


}

export default BCActions;