import React from 'react';
import { Col, Image, Row, Typography } from 'antd';
import { themeColor } from '../assets/theme';
const { Title } = Typography;
interface CardProps {
  image: string;
  description: string;
  style?: React.CSSProperties;
  title: string
}

const ShadowCard: React.FC<CardProps> = ({ image, description, style, title }) => {
  return (
    <div
      style={{
        borderRadius: 25,
        boxShadow: '0px 2px 8px rgba(126, 116, 116, 0.2)',
        // backgroundColor: "#240a4b,0.5",
        backgroundColor: "rgb(255, 255, 255,0.1)",
        width: "100%",
        padding: 20,
        ...style,

      }}
    >
      <Row>
        <Col
          flex="200px"
          style={{
            // display: 'flex',
            // justifyContent: 'center',
            width: "100%"
          }}>

          <Image
            src={image}
            style={{
              height: 300
            }}
          >
          </Image>


        </Col>
        <Col flex="auto">
          <Title color='#fff'>{title}</Title>
          <p>
            {description}
          </p>

        </Col>
      </Row>
    </div>
  );
};

export default ShadowCard;
