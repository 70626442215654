import { TableName } from '../constants/TableName.constants';
import firebase from '../firebase';
import { IUser } from '../interface/data/IUser.interface';
import AuthActions from '../reduxs/auth/AuthActions';
const firestore = firebase.firestore();
const auth = firebase.auth();
const { setAuth } = AuthActions

export const getAuth = () => {
    auth.onAuthStateChanged(async (user) => {
        if (user) {
            // User is signed in, set the user to Redux state
            const userDoc = await firestore.collection(TableName.Users).doc(user.uid).get()
            const userData = userDoc.data()
            if (userData) {
                return userData
                // const user: IUser = {
                //     uid: userData.uid,
                //     contractAddress: userData.contractAddress,
                //     role: userData.role,
                //     name: userData.name,

                // }
                // setAuth(true, user)
            }

        }
        return null
    })
}
export const createUserMetaMask = async (address: string) => {
    const userDoc = await firestore.collection(TableName.Users).where("contractAddress", '==', address).get()
    if (userDoc.size === 0) {
        console.log("new address user", address)
        await firestore.collection(TableName.Users).add({
            contractAddress: address
        })
    }
}




