
import { useNavigate } from "react-router-dom";
import styles from "../styles/Marketplace.module.css";

const MarketplacePage = () => {
  const navigate = useNavigate();

  return (

    <div >
      {/* Top Section */}



      <main className={styles.main}>
        <img src="https://bafkreidpx2y22ezkk6pdofazbwyszzmanxkwyrhhv5qcudk6xsxx4bfm2i.ipfs.nftstorage.link" alt="" />
        <h1 className={styles.h1}>Marketplace</h1>
      </main>

      <h2 className={styles.h2}>NFTs Collection</h2>
      <div className={styles.nftBoxGrid}>
        <div className={styles.optionSelectBox}>
          {/* Mint a new NFT */}
          <div className={styles.title}>
            <h2 className={styles.h2}>Peace Robot</h2>
            <h4 className={styles.h4}>Collection : Baby</h4>
          </div>
          <div className={styles.body}>
            <img src={`https://gateway.pinata.cloud/ipfs/QmZPU2ufbP1vPvhCzwVN4D4JEv8vb1WeigdhFrwJV8xE1z/box%20peace%2004.gif`} alt="drop" />
            <button
              className={`${styles.mainButton} ${styles.spacerBottom}`}
              onClick={() => navigate(`/nft/mint`)}>
              Mint An NFT
            </button>
          </div>
          <p className={styles.selectBoxDescription}>
            Use the NFT Drop Contract to claim an NFT from the collection.
          </p>
        </div>

        <div className={styles.optionSelectBox}>
          {/* Mint a new NFT */}
          <div className={styles.title}>
            <h2 className={styles.h2}>Peace Robot</h2>
            <h4 className={styles.h4}>Collection : Little</h4>
          </div>
          <div className={styles.body}>
            <img src={`https://gateway.pinata.cloud/ipfs/QmZPU2ufbP1vPvhCzwVN4D4JEv8vb1WeigdhFrwJV8xE1z/box%20peace%2004.gif`} alt="drop" />
            <button
              className={`${styles.mainButton} ${styles.spacerBottom}`}
              onClick={() => navigate(`/nft/mint`)}>
              Mint An NFT
            </button>
          </div>
          <p className={styles.selectBoxDescription}>
            Use the NFT Drop Contract to claim an NFT from the collection.
          </p>
        </div>
        <div className={styles.optionSelectBox}>
          {/* Mint a new NFT */}
          <div className={styles.title}>
            <h2 className={styles.h2}>Peace Robot</h2>
            <h4 className={styles.h4}>Collection : Cool</h4>
          </div>
          <div className={styles.body}>
            <img src={`https://gateway.pinata.cloud/ipfs/QmZPU2ufbP1vPvhCzwVN4D4JEv8vb1WeigdhFrwJV8xE1z/box%20peace%2004.gif`} alt="drop" />
            <button
              className={`${styles.mainButton} ${styles.spacerBottom}`}
              onClick={() => navigate(`/nft/mint`)}>
              Mint An NFT
            </button>
          </div>
          <p className={styles.selectBoxDescription}>
            Use the NFT Drop Contract to claim an NFT from the collection.
          </p>
        </div>
      </div>
    </div>
  );
};

export default MarketplacePage;
