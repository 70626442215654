import { CloseOutlined, MenuOutlined, MenuUnfoldOutlined } from "@ant-design/icons"
import { useAddress, useDisconnect, useMetamask, } from "@thirdweb-dev/react"
import { Col, Menu, Row, Image, Drawer, Button, MenuItemProps, MenuProps } from "antd"
import React, { Component, } from "react"
import { connect } from "react-redux"
import { Link, useLocation, useNavigate } from "react-router-dom"
import { themeColor } from "../assets/theme"
import MButton from "../components/MButton"
import "../styles/Navbar.styles.css"
import { hideSomeText } from './../methods/logics';
import blockchainActions from "../reduxs/blockchain/BCActions"
import firebase from '../firebase';
import AuthActions from "../reduxs/auth/AuthActions"
import { useForm } from "react-hook-form"
import { RoleName } from "../constants/RoleName.constants"
import { TableName } from "../constants/TableName.constants"
import { IUser } from "../interface/data/IUser.interface"
import { createUserMetaMask } from "../services/FirebaseServices"
import ModalConnectMetamask from "../components/ModalConnectMetamask"
const auth = firebase.auth();
const firestore = firebase.firestore();
interface Props {
    isLogin: boolean
    loading: boolean
    address: ReturnType<typeof useAddress>
    setAddress: Function
    setAuth: Function
    navigate: ReturnType<typeof useNavigate>
    connectMetaMask: ReturnType<typeof useMetamask>
    disconnect: ReturnType<typeof useDisconnect>
    location: ReturnType<typeof useLocation>
    user: IUser
}
interface State {
    openMenu: boolean,
    linkMetamask: string,
    device: "web" | "android" | "ios",
    modalVisible: boolean,
    admin: boolean
}
type MenuItem = Required<MenuProps>['items'][number];
function getItem(
    key: React.Key,
    name: string,
    label: React.ReactNode,
    icon?: React.ReactNode,
    children?: MenuItem[],
    type?: 'group',
): MenuItem {
    return {
        key,
        icon,
        name,
        children,
        label,
        type,
    } as MenuItem;
}
export class AppMenu extends Component<Props, State>{
    constructor(props: Props) {
        super(props)
        this.state = {
            openMenu: false,
            device: "web",
            linkMetamask: "",
            modalVisible: false,
            admin: props.location.pathname.startsWith("/admin") ? true : false
        }


    }
    componentDidMount(): void {

    }

    setOpenMenu = (status: boolean) => {
        this.setState({
            openMenu: status
        })
    }
    connectWithMetamask = async () => {
        // this.props.connectWithMetamask().then(async (result: any) => {
        //     if (result.data.account) {
        //         await firestore.collection(TableName.Users).doc(result.data.account).update({
        //             contractAddress: result.data.account
        //         })
        //         const { contractAddress } = this.props.user
        //         this.props.setAuth(true, { address: contractAddress });
        //     }

        // })


        this.props.connectMetaMask().then(async (result: any) => {
            if (result.data.account) {
                await createUserMetaMask(result.data.account)

            }
        })


    }
    disconnectMetamask = async () => {
        this.props.disconnect()
        this.setState({
            openMenu: false
        })
    }
    render() {
        console.log(this.state.modalVisible)
        let items: MenuProps['items'] = [
            // getItem(1, '/home', <Link to="/">Home</Link>, null),
            getItem(2, '/docs', <Link to="/docs">Docs</Link>, null),
            getItem(3, '/nft', "NFT", null, [
                getItem(4, '/nft/stake', <Link to="/nft/stake">StakeNFTs</Link>, null),
                getItem(5, '/nft/mint', <Link to="/nft/mint">MintNFT</Link>, null),
                getItem(6, '/nft/collection', <Link to="/nft/collection">Collection</Link>, null),
            ]),
            getItem(13, '/peace-script-shop', <Link to="/peace-script-shop">PeaceScript Shop</Link>, null),
            getItem(7, '/events', <Link to="/events">Event</Link>, null),


        ]
        if (this.props.isLogin) {
            if (this.props.user.role === RoleName.Admin) {
                items = [
                    // getItem(1, '/home', <Link to="/">Home</Link>, null),
                    getItem(2, '/docs', <Link to="/docs">Docs</Link>, null),
                    getItem(3, '/nft', "NFT", null, [
                        getItem(4, '/nft/stake', <Link to="/nft/stake">StakeNFTs</Link>, null),
                        getItem(5, '/nft/mint', <Link to="/nft/mint">MintNFT</Link>, null),
                        getItem(6, '/nft/collection', <Link to="/nft/collection">Collection</Link>, null),
                    ]),
                    getItem(13, '/peace-script-shop', <Link to="/peace-script-shop">PeaceScript Shop</Link>, null),
                    getItem(7, '/events', <Link to="/events">Event</Link>, null),
                    // getItem(8, '/products', <Link to="/products">Products</Link>, null),
                    getItem(9, '/manages', "Manages", null, [
                        getItem(10, '/manages/dashboard', <Link to="/manages/dashboard">Dashboard</Link>, null),
                        getItem(11, '/manages/products', <Link to="/manages/products">Product</Link>, null),
                    ]),
                    // getItem(12, '/peace-bot', <Link to="/peace-bot">PeaceBot</Link>, null),
                ]
            } else {
                // member
                items = [
                    // getItem(1, '/home', <Link to="/">Home</Link>, null),
                    getItem(2, '/docs', <Link to="/docs">Docs</Link>, null),
                    getItem(3, '/nft', "NFT", null, [
                        getItem(4, '/nft/stake', <Link to="/nft/stake">StakeNFTs</Link>, null),
                        getItem(5, '/nft/mint', <Link to="/nft/mint">MintNFT</Link>, null),
                        getItem(6, '/nft/collection', <Link to="/nft/collection">Collection</Link>, null),
                    ]),
                    getItem(13, '/peace-script-shop', <Link to="/peace-script-shop">PeaceScript Shop</Link>, null),
                    getItem(7, '/events', <Link to="/events">Event</Link>, null),
                    // getItem(8, '/products', <Link to="/products">Products</Link>, null),
                    // getItem(12, '/peace-bot', <Link to="/peace-bot">PeaceBot</Link>, null),

                ]
            }

        } else if (this.state.admin) {
            items = []
        }
        // const index = items.findIndex(item => item. === location.pathname);

        let i = 1;
        const pathname = this.props.location.pathname;
        items.forEach((element: any) => {
            if (element.children) {
                if (element.name === pathname) {
                    i = element.key
                } else {
                    element.children.forEach((element2: any) => {
                        if (element2.name === pathname) {
                            i = element2.key
                        }
                    })
                }
            } else {
                if (element.name === pathname) {
                    i = element.key
                }
            }

        });

        const { openMenu } = this.state
        const { address } = this.props
        return (
            <div>
                <div >

                    <Row wrap={false}>
                        <Col flex="none">
                            <Image preview={false} height={48} width={48} style={{ marginTop: -5 }} src="https://bafkreiezyb6kccy7cefx3nh6njeotdproh5ucwsfik2t4m3lnwgbmbywku.ipfs.nftstorage.link/" alt="Brand" />
                        </Col>

                        <>
                            <Col flex="auto" className="fullWidth" style={{ marginLeft: 5 }}>
                                <Menu
                                    theme="dark"
                                    style={{ backgroundColor: themeColor.navbar, justifyContent: "end", height: 61.5 }}
                                    mode="horizontal"
                                    defaultSelectedKeys={['1']}
                                    selectedKeys={[i.toString()]}
                                    items={items}
                                >
                                </Menu>
                            </Col>
                            <Col className="fullWidth">
                                {this.state.admin ?
                                    this.props.isLogin ? this.props.user.name : <p>Admin</p>

                                    :
                                    !address ? (
                                        <MButton onClick={() => this.connectWithMetamask()}>
                                            Connect Wallet
                                        </MButton>
                                    ) : (
                                        <>
                                            <MButton onClick={() => this.disconnectMetamask()}>
                                                <p>{hideSomeText(address)}</p>
                                            </MButton>
                                        </>
                                    )
                                }
                                {/* {this.props.isLogin &&
                                    <MButton onClick={() => {
                                        auth.signOut().then(() => {
                                            // การล็อกเอาต์สำเร็จ
                                            console.log("logout success")
                                            this.props.setAuth(false)
                                            this.props.navigate("/login")

                                        }).catch((error) => {
                                            // เกิดข้อผิดพลาดในการล็อกเอาท์
                                        });
                                    }
                                    }
                                        style={{ backgroundColor: themeColor.red, color: "white", borderWidth: 0 }}>logout</MButton>


                                } */}



                            </Col>
                            <Col flex="auto" className="minWidth" style={{ justifyContent: "flex-end" }}>

                                <div style={{ marginTop: 6 }}>
                                    <MenuOutlined style={{ color: 'white', fontSize: 30 }}
                                        onClick={() => {
                                            this.setOpenMenu(true)
                                        }}
                                    ></MenuOutlined>
                                </div>

                                <Drawer
                                    open={openMenu}
                                    onClose={() => {
                                        this.setOpenMenu(false)
                                    }}
                                    style={{ backgroundColor: themeColor.navbar }}
                                    placement="right"
                                    closable={true}
                                    closeIcon={<CloseOutlined style={{ color: 'white' }} />}
                                >
                                    <Menu
                                        theme="dark"
                                        style={{ backgroundColor: themeColor.navbar }}
                                        mode={openMenu ? "inline" : "horizontal"}
                                        defaultSelectedKeys={['1']}
                                        selectedKeys={[i.toString()]}
                                        items={items}

                                        onClick={() => {
                                            this.setOpenMenu(false)
                                        }}
                                    />
                                    {this.state.admin ?
                                        this.props.isLogin ? this.props.user.name : <p>Admin</p>
                                        :
                                        !address ? (
                                            <MButton onClick={() => this.connectWithMetamask()}>
                                                Connpaect Wallet
                                            </MButton>
                                        ) : (
                                            <>
                                                <MButton onClick={() => this.disconnectMetamask()}>
                                                    <p>{hideSomeText(address)}</p>
                                                </MButton>
                                            </>
                                        )

                                    }


                                    {/* {this.props.isLogin &&
                                        <MButton onClick={() => {
                                            auth.signOut().then(() => {
                                                // การล็อกเอาต์สำเร็จ
                                                console.log("logout success")
                                                this.props.setAuth(false)
                                                this.setOpenMenu(false)
                                                this.props.navigate("/login")

                                            }).catch((error) => {
                                                // เกิดข้อผิดพลาดในการล็อกเอาท์
                                            });
                                        }
                                        }
                                            style={{ backgroundColor: themeColor.red, color: "white", borderWidth: 0 }}>logout</MButton>
                                    } */}
                                </Drawer>
                            </Col>
                        </>
                    </Row>
                    <ModalConnectMetamask
                        open={this.state.modalVisible}
                        handleOk={() => {
                            if (this.state.device === "web") {
                                window.location.href = 'https://chrome.google.com/webstore/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn';
                            }
                        }}
                        okText={this.state.device === "web" ? "ติดตั้ง" : "ดาวน์โหลด"}
                    >

                    </ModalConnectMetamask>
                </div>
            </div>

        )
    }
}

function withFormHOC(Component: React.ComponentType<Props>) {
    function UseForm(props: any) {
        const form = useForm({
            mode: 'onTouched',
        });
        const navigate = useNavigate()
        const location = useLocation();
        const address = useAddress();
        const connectMetaMask = useMetamask()
        const disconnect = useDisconnect()
        return <Component form={form} {...props}
            navigate={navigate}
            location={location}
            address={address}
            connectMetaMask={connectMetaMask}
            disconnect={disconnect}


        />
    }
    return UseForm;
}
const { setAddress } = blockchainActions;
const { setAuth } = AuthActions
const mapState = ({ AuthReducers, BCReducers }: any) => {
    return {
        isLogin: AuthReducers.isLogin,
        loading: AuthReducers.loading,
        user: AuthReducers.user,
    };
};

export default connect(mapState, { setAddress, setAuth })(withFormHOC(AppMenu))