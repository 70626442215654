
import { Row, Col, Carousel } from 'antd';
import { themeColor } from './../assets/theme';
interface listData {
    name: string,
    detail: string
}
interface Props {
    children?: React.ReactNode;
    style?: React.CSSProperties;
    list: listData[]
}
const MCarousel: React.FC<Props> = ({ style, children, list }) => {
    return <div >
        <div className="minWidthCarousel">
            <Carousel
                style={{
                    padding: 20,
                    backgroundColor: themeColor.navbar,
                    borderRadius: 10,
                    border: '1px solid #05b4ff',
                    ...style
                }}
            >
                {list.map((item: listData, i: number) =>
                    <div
                        key={i}
                    >
                        <p className='subTitle'>
                            {item.name}
                        </p>
                        <hr style={{ width: "80%", borderColor: "grey", opacity: "0.25" }} />
                        <p>
                            {item.detail}
                        </p>
                    </div>
                )}
            </Carousel >
        </div>
        <div>
            <Row className='fullWidth' style={{ justifyContent: "center" }}>
                {list.map((item: listData, i: number) =>
                    <Col
                        key={i}
                        style={{
                            padding: 20,
                            backgroundColor: themeColor.navbar,
                            borderRadius: 10,
                            border: '1px solid #05b4ff',
                            width: 150,
                            margin: 10,
                            ...style
                        }}>
                        <p className='subTitle'>
                            {item.name}
                        </p>
                        <hr style={{ width: "80%", borderColor: "grey", opacity: "0.25" }} />
                        <p>
                            {item.detail}
                        </p>
                    </Col>
                )}
            </Row>
        </div>

    </div>

}

export default MCarousel;