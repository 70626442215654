import { RoleName } from "../../constants/RoleName.constants";
import { IUser } from "../../interface/data/IUser.interface";
import AuthActions from "./AuthActions";


export interface S {
  isLogin: boolean;
  loading: boolean;
  user: IUser;

}
// const isLoggedIn = localStorage.getItem("isLoggedIn") === "ok" ? true : false;
// const selectBranch = localStorage.getItem("selectBranch") === "ok" ? true : false;

const initState: S = {
  isLogin: false,
  loading: false,
  user: {
    role: RoleName.Guest
  },


};
export default function (state: S = initState, action: any) {
  switch (action.type) {
    case AuthActions.SET_AUTH:
      return {
        ...state,
        isLogin: action.payload,
        user: action.user,
        role: action.role
      }
    case AuthActions.LOGIN_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case AuthActions.LOGIN_SUCCESS:
      return {
        ...state,
        loading: false,
        isLogin: true
      };
    case AuthActions.LOGIN_ERROR:
      return {
        ...state,
        loading: false,
      };

    case AuthActions.LOGOUT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case AuthActions.LOGOUT_SUCCESS:
      return {
        ...state,
        loading: false,
        isLogin: false
      };
    case AuthActions.LOGOUT_ERROR:
      return {
        ...state,
        loading: false,
      };

    default:
      return state;
  }
}
